import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.button`
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.primary};
  border-radius: 40px;
  border: none;
  opacity: 1;

  svg {
    height: ${props => (props.theme.space.m)};
  }
`

const Content = styled.div`
  color: ${props => props.color ? props.color : props.theme.colors.white};
  padding-top: ${props => props.size ? props.size : props.theme.space.m};
  padding-right: ${props => props.size ? props.size : props.theme.space.m};
  padding-bottom: ${props => props.size ? props.size : props.theme.space.m};
  padding-left: ${props => props.size ? props.size : props.theme.space.m};
  font-size: ${props => props.theme.space.m};
  font-weight: bold;
`

function Button(props) {
  let size = props.size
  let color = props.color
  let backgroundColor = props.backgroundColor

  return (
    <Wrapper
      id={props.id ? props.id : 'button'}
      type={props.submit ? 'submit' : ''}
      disabled={props.disabled}
      onClick={props.onClick}
      size={size}
      backgroundColor={backgroundColor}
      ref={props.anchorRef}
    >
      <Content
        size={size}
        color={color}
      >
        {props.content}
      </Content>
    </Wrapper>
  )
}

export default Button
