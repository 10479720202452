import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import { ChevronDown } from 'react-bootstrap-icons'
import { useDevice } from 'functions'

const ButtonWrapper = styled.div`
  padding-right: 15px;

  #button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2.5px;
    padding-bottom: 2.5px;

    #button-text {
      padding-left: 5px;
    }
  }

  svg {
    height: 25px;
    width: 25px;
    padding-left: 5px;
  }
`

function UseTemplateButton(props) {
  const device = useDevice()

  let buttonText = device === 'mobile' || device === 'tablet' ? '' : 'Use template'

  return (
    <ButtonWrapper id="use-template-button">
      <Button
        content={<span><ChevronDown /> {buttonText}</span>}
        anchorRef={props.anchorRef}
        onClick={props.onClick}
        size={props => props.theme.space.s}
      />
    </ButtonWrapper>
  )
}

export default UseTemplateButton
