import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  // padding-top: ${props => props.theme.space.l};
  padding-bottom: ${props => props.theme.space.l};

  h3 {
    padding-bottom: ${props => props.theme.space.s};
    color: ${props => props.theme.colors.grey};
  }
`

function GridSectionHeading(props) {
  return (
    <Wrapper>
      <h3>{props.heading}</h3>
      <p>{props.description}</p>
    </Wrapper>
  )
}

export default GridSectionHeading