import React from 'react'
import { Search } from 'react-bootstrap-icons'
import Button from './Button';

function SearchForResourceButton(props) {
  return (
    <Button
      disabled={props.disabled}
      onClick={props.onClick}
      content={<span><Search /> {props.text}</span>}
      backgroundColor={props => props.theme.colors.orange}
      color={props => props.theme.colors.white}
      size={props => props.theme.space.s}
    />
  )
}

export default SearchForResourceButton
