import React from 'react'
import styled from 'styled-components'
import { ControlledInputWrapper } from 'forms'
import { LabelWrapper } from 'forms'

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 25px;
`

const ControlledTextInput = props => {
  function labelWrapper() {
    if (props.label) {
      return (
        <LabelWrapper
          label={props.label}
          name={props.name}
          toolTipContent={props.toolTipContent}
        />
      )
    }
  }
  return (
    <FieldWrapper id="text-input">
      {labelWrapper()}
      <ControlledInputWrapper
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={props.type}
        submitForm={props.submitForm}
        handleChange={props.handleChange}
        submitOnChange={props.submitOnChange}
        onBlur={props.onBlur}
        onClick={props.onClick}
        max={props.max}
        onKeyPress={props.onKeyPress}
        value={props.value}
      />
    </FieldWrapper>
  )
}

export default ControlledTextInput
