import React from 'react'
import styled from 'styled-components'
import { MentionWrapper } from 'forms'
import { LabelWrapper } from 'forms'

const FieldWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

function MentionInput(props) {
  function labelWrapper() {
    if (props.label) {
      return (
        <LabelWrapper
          label={props.label}
          name={props.name}
          toolTipContent={props.toolTipContent}
        />
      )
    }
  }

  return (
    <FieldWrapper id="mention-input">
      {labelWrapper()}
      {/* <Col md="8" sm="7" xs="6"> */}
      <MentionWrapper
        disabled={props.disabled}
        placeholder={props.placeholder}
        name={props.name}
        type={props.type}
        submitForm={props.submitForm}
        handleChange={props.handleChange}
        submitOnChange={props.submitOnChange}
        onBlur={props.onBlur}
        onClick={props.onClick}
        options={props.options}
        setFieldValue={props.setFieldValue}
        text={props.text}
        mentionRef={props.mentionRef}
      />
      {/* </Col> */}
      {props.children}
    </FieldWrapper>
  )
}

export default MentionInput
