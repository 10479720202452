function archiveAll({
  enqueueSnackbar: enqueueSnackbar,
  records: records,
  showModal: showModal,
  archive: archive,
  redirectUrl: redirectUrl,
}) {

  if (records.length === 0) {
    showModal(false)
  } else {
    showModal(false)
    enqueueSnackbar('Records are being archived.', { variant: 'info' })

    records.map(function (record) {
      return (
        archive({
          id: record.id,
          showModal: showModal,
          enqueueSnackbar: enqueueSnackbar,
        })
      )
    })
  }

  if (redirectUrl) {
    window.setTimeout(() => {
      window.location.href = redirectUrl
    }, 2000)
  }

}

export default archiveAll