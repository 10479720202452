import React from 'react'
import { TextInput } from 'forms'
import PropTypes from 'prop-types'

function UrlInput(props) {
  return (
    <TextInput
      id={props.id}
      label={props.label}
      name={props.name}
      type="string"
      placeholder="http://"
      toolTipContent={props.toolTipContent}
      required={props.required}
    />
  )
}

export default UrlInput

UrlInput.propTypes = {
  required: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string,
}
