import React from 'react'
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components'

const Wrapper = styled.button`
  background: none;
  border: none;

  .badge {
    font-size: 14px;
  }

  .bg-primary {
    background-color: ${props => props.theme.colors.primary} !important;
    color: white;
  }
`

function Counter(props) {
  return (
    <Wrapper id='counter'>
      <Badge pill>{props.count}</Badge>
    </Wrapper>
  )
}

export default Counter
