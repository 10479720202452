import React from 'react'
import styled from 'styled-components'
import { Label } from 'forms'
import { LabelWithoutErrorMessage } from 'forms'
import {ToolTip} from 'functional-components'
import {VideoToolTip} from 'functional-components'

const Wrapper = styled.div`
  display: flex;

  #tooltip {
    padding-left: ${props => props.theme.space.m};
  }
`

function LabelWrapper(props) {
  function labelText() {
    if (props.required) {
      return `${props.label} *`
    } else {
      return props.label
    }
  }

  function label() {
    if (props.name) {
      return <Label text={labelText()} name={props.name} array={props.array} />
    } else {
      return <LabelWithoutErrorMessage text={labelText()} />
    }
  }

  function toolTip() {
    if (props.toolTipContent) {
      return <ToolTip content={props.toolTipContent} />
    }
  }

  function videoTooltip() {
    if (props.videoToolTipUrl) {
      return (
        <VideoToolTip
          url={props.videoToolTipUrl}
          heading={props.videoToolTipHeading}
        />
      )
    }
  }

  return (
    <Wrapper id="label-wrapper" width={props.width}>
      {label()}
      {toolTip()}
      {videoTooltip()}
    </Wrapper>
  )
}

export default LabelWrapper
