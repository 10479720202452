import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: ${props => (props.height ? props.height : '76px')};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
`

function Navbar(props) {
  return (
    <Wrapper
      id="navbar"
      backgroundColor={props.backgroundColor}
      height={props.height}
    >
      {props.children}
    </Wrapper>
  )
}

export default Navbar
