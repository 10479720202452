import React from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import {LargeGreenTick} from 'assets'

const ModalBodyWrapper = styled.div`
  text-align: center;
`

const SuccessIcon = styled.div`
  padding-top: 50px;
`

const ModalHeading = styled.div`
  padding-top: 30px;
  padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;

  color: ${props => props.theme.colors.black};
`

const ModalDescription = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;

  color: ${props => props.theme.colors.black};
`

const ModalFooterWrapper = styled.div`
  .modal-footer {
    display: flex;
    justify-content: center;
    #button {
      height: 52px;
      width: 212px;
      border-radius: 8px;
      font-size: 18px;
    }
  }
`

function SuccessModal(props) {
  let description

  if (props.description) {
    description = <ModalDescription>{props.description}</ModalDescription>
  }

  let footer

  if (props.children) {
    footer = (
      <ModalFooterWrapper>
        <Modal.Footer>{props.children}</Modal.Footer>
      </ModalFooterWrapper>
    )
  }

  return (
    <Modal centered show={props.show}>
      <ModalBodyWrapper>
        <Modal.Body>
          <SuccessIcon>
            <Image src={LargeGreenTick} />
          </SuccessIcon>
          <ModalHeading>{props.heading}</ModalHeading>
          {description}
        </Modal.Body>
        {footer}
      </ModalBodyWrapper>
    </Modal>
  )
}

export default SuccessModal
