import update from 'immutability-helper'
import { FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_REQUEST } from 'v2/spas/account-user/activity-feed-items/redux/action-types.tsx'
import { FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_SUCCESS } from 'v2/spas/account-user/activity-feed-items/redux/action-types.tsx'
import { FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_FAILURE } from 'v2/spas/account-user/activity-feed-items/redux/action-types.tsx'
import { ARCHIVE_ACTIVITY_FEED_ITEM_REQUEST } from 'v2/spas/account-user/activity-feed-items/redux/action-types.tsx'
import { ARCHIVE_ACTIVITY_FEED_ITEM_SUCCESS } from 'v2/spas/account-user/activity-feed-items/redux/action-types.tsx'
import { ARCHIVE_ACTIVITY_FEED_ITEM_FAILURE } from 'v2/spas/account-user/activity-feed-items/redux/action-types.tsx'
import { DELETE_ACTIVITY_FEED_ITEM_REQUEST } from 'v2/spas/account-user/activity-feed-items/redux/action-types.tsx'
import { DELETE_ACTIVITY_FEED_ITEM_SUCCESS } from 'v2/spas/account-user/activity-feed-items/redux/action-types.tsx'
import { DELETE_ACTIVITY_FEED_ITEM_FAILURE } from 'v2/spas/account-user/activity-feed-items/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  accountUser: {},
  activityFeedItems: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        accountUser: {
          $set: action.payload.accountUser,
        },
        activityFeedItems: {
          $set: action.payload.accountUser.activityFeedItems
        },
      })
    }

    case ARCHIVE_ACTIVITY_FEED_ITEM_REQUEST: {
      return state
    }

    case ARCHIVE_ACTIVITY_FEED_ITEM_FAILURE: {
      return state
    }

    case ARCHIVE_ACTIVITY_FEED_ITEM_SUCCESS: {
      let index = state.activityFeedItems.findIndex(x => x.id === action.payload.activityFeedItem.id)

      return update(state, {
        activityFeedItems: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_ACTIVITY_FEED_ITEM_REQUEST: {
      return state
    }
    
    case DELETE_ACTIVITY_FEED_ITEM_FAILURE: {
      return state
    }
    
    case DELETE_ACTIVITY_FEED_ITEM_SUCCESS: {
      let index = state.activityFeedItems.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        activityFeedItems: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
