function useFullNameInitials(fullName) {
  if (fullName) {
    return fullName
      .split(' ', 2)
      .map(name => name[0])
      .join('')
  } else {
    return ''
  }
}

export default useFullNameInitials
