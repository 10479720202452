import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Label } from 'forms'
import { InputWrapper } from 'forms'
import $ from 'jquery'
import {connect} from 'react-redux'
import {fetchFormattedAddress} from 'mutations'
import { TextInput } from 'forms'
import { SelectInputWithValue } from 'forms'
import PropTypes from 'prop-types'

const Wrapper = styled.div``

const FieldWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: ${props => props.theme.space.l};
  display: ${props => (props.address.id ? 'none' : '')};
`

const AddressField = styled.div`
  display: ${props => (props.address.id ? '' : 'none')};
`

function AddressSearch(props) {
  const [country, setCountry] = useState(
    props.address.id ? props.address.country : '',
  )

  useEffect(() => {
    initiateGoogleAddressSearch(
      props.setFieldValue,
      props.fetchFormattedAddress,
      props.values,
    )
  }, [])

  let countryOptions = [
    {name: '', value: ''},
    {name: 'England', value: 'England'},
    {name: 'Northern Ireland', value: 'Northern Ireland'},
    {name: 'Wales', value: 'Wales'},
    {name: 'Scotland', value: 'Scotland'},
    {name: 'Ireland', value: 'Ireland'},
  ]

  function handlePlaceSelect(
    autocomplete,
    setFieldValue,
    fetchFormattedAddress,
    searchTerm,
    values,
  ) {
    let addressObject = autocomplete.getPlace()
    let address = addressObject.address_components

    if (address) {
      fetchFormattedAddress(address, setFieldValue, setCountry)
      setFieldValue('googleMapsUrl', addressObject.url)
    } else {
      setFieldValue('addressLine1', searchTerm)
    }

    $('#field-wrapper').slideUp(500)
    $('#addressLine1').fadeIn(500)
    $('#addressLine2').fadeIn(500)
    $('#city').fadeIn(500)
    $('#region').fadeIn(500)
    $('#zipCode').fadeIn(500)
    $('#country').fadeIn(500)
  }

  function initiateGoogleAddressSearch(
    setFieldValue,
    fetchFormattedAddress,
    values,
  ) {
    let input = $('#address-search')[0]
    let options = {
      types: ['geocode'],
      componentRestrictions: {
        country: ['gb', 'ie']
      },
    }

    const google = window.google

    const autocomplete = new google.maps.places.Autocomplete(input, options)

    google.maps.event.addDomListener(input, 'keydown', function (event) {
      if (event.keyCode === 13) {
        event.preventDefault()
      }
    })

    autocomplete.addListener('place_changed', () => {
      handlePlaceSelect(
        autocomplete,
        setFieldValue,
        fetchFormattedAddress,
        input.value,
        values,
      )
    })
  }

  function handleCountryChange(event) {
    setCountry(event.target.value)
    props.setFieldValue('country', event.target.value)
  }

  return (
    <Wrapper>
      <FieldWrapper address={props.address} id="field-wrapper">
        <Label text="Street Name / Postcode" name="Street Name / Postcode" />
        <InputWrapper id="address-search" name="addressSearch" type="text" />
      </FieldWrapper>

      <AddressField address={props.address} id="addressLine1">
        <TextInput label="Address line 1" name="addressLine1" type="text" required/>
      </AddressField>

      <AddressField address={props.address} id="addressLine2">
        <TextInput label="Address line 2" name="addressLine2" type="text" />
      </AddressField>

      <AddressField address={props.address} id="city">
        <TextInput label="City" name="city" type="text" required />
      </AddressField>

      <AddressField address={props.address} id="region">
        <TextInput label="Region" name="region" type="text" />
      </AddressField>

      <AddressField address={props.address} id="zipCode" >
        <TextInput label="Postcode" name="zipCode" type="text" required />
      </AddressField>

      <AddressField address={props.address} id="country" >
        <SelectInputWithValue
          label="Country"
          name="country"
          type="string"
          value={country}
          options={countryOptions}
          handleChange={handleCountryChange}
          required
        />
      </AddressField>
    </Wrapper>
  )
}

const mapDispatchToProps = {
  fetchFormattedAddress: fetchFormattedAddress,
}

export default connect(null, mapDispatchToProps)(AddressSearch)

AddressSearch.propTypes = {
  property: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  fetchFormattedAddress: PropTypes.func.isRequired,
}
