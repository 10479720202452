function deleteAll({
  enqueueSnackbar: enqueueSnackbar,
  records: records,
  showModal: showModal,
  deleteMutation: deleteMutation,
  deleteUrl: deleteUrl,
}) {

  enqueueSnackbar('Records are being deleted.', { variant: 'info' })

  records.map(function (record) {
    return (
      deleteMutation({
        id: record.id,
        showModal: showModal,
        enqueueSnackbar: enqueueSnackbar,
      })
    )
  })

  if (deleteUrl) {
    window.setTimeout(() => {
      window.location.href = deleteUrl
    }, 2000)
  }
}

export default deleteAll