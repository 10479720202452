function processServerError({
  dispatch: dispatch,
  handler: handler,
  error: error,
  enqueueSnackbar: enqueueSnackbar,
}) {
  dispatch(handler({
    error: error,
  }))

  if (enqueueSnackbar) {
    enqueueSnackbar(error, { variant: 'error' })
  }
}

export default processServerError