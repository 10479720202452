import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.offWhite};
  min-height: 100vh;
  padding-top: ${props => (props.paddingTop ? props.paddingTop : props.theme.space.xl)};
  padding-bottom: ${props => props.theme.space.xl};
  display: flex;
  justify-content: center;

  @media ${props => props.theme.devices.desktop} {
    padding-left: ${props => props.theme.space.l};
    padding-right: ${props => props.theme.space.l};
  }

  @media ${props => props.theme.devices.laptop} {
    padding-left: ${props => props.theme.space.l};
    padding-right: ${props => props.theme.space.l};
  }
`

function Page(props) {
  return (
    <Wrapper id="page" paddingTop={props.paddingTop}>
      {props.children}
    </Wrapper>
  )
}

export default Page
