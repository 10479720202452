import React from 'react'
import { CoverDisabled } from 'functional-components'

const MainRouteContainer = ({ children }) => {
  return (
    <React.Fragment>
      <CoverDisabled>
        {children}
      </CoverDisabled>
    </React.Fragment>
  )
}

export default MainRouteContainer