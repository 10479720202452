import {LOGOUT_REQUEST} from 'redux/actionTypes.js'
import {LOGOUT_SUCCESS} from 'redux/actionTypes.js'
import {LOGOUT_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function logout(handleResponse) {
  return function (dispatch) {
    dispatch(logoutRequest())

    let variables = {}

    let query = `
      mutation {
        signOut {
          message
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(logoutFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          dispatch(logoutSuccess())
          Cookies.remove('jwtToken')
          handleResponse({
            status: 'success',
          })
        }
      })
      .catch(error => {
        dispatch(logoutFailure())
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
})

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
})

export const logoutFailure = error => ({
  type: LOGOUT_FAILURE,
  payload: {
    error: error,
  },
})
