// import { ACCOUNT_SEARCH_REQUEST } from 'redux/actionTypes.js'
// import { ACCOUNT_SEARCH_SUCCESS } from 'redux/actionTypes.js'
// import { ACCOUNT_SEARCH_FAILURE } from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

function accountSearch(term, handleResponse) {
  return function (dispatch) {
    // dispatch(accountSearchRequest())

    let variables = {
      term: term,
    }

    let query = `
      mutation($term: String!) {
        accountSearch(
          term: $term
        ) {
          id
          name
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          // dispatch(accountSearchFailure(res.data.errors[0].message))
          handleResponse({ status: 'error', error: res.data.errors[0].message })
        } else {
          // dispatch(accountSearchSuccess(res.data.data.accountSearch))
          handleResponse({
            status: 'success',
            accountSearchResults: res.data.data.accountSearch,
          })
        }
      })
      .catch(error => {
        // dispatch(accountSearchFailure(error))
        handleResponse({ status: 'error', error: error.message })
      })
  }
}

// export const accountSearchRequest = () => ({
//   type: ACCOUNT_SEARCH_REQUEST,
// })

// export const accountSearchSuccess = accounts => ({
//   type: ACCOUNT_SEARCH_SUCCESS,
//   payload: {
//     accounts: accounts,
//   },
// })

// export const accountSearchFailure = error => ({
//   type: ACCOUNT_SEARCH_FAILURE,
//   payload: {
//     error: error.message,
//   },
// })

export default accountSearch
