import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#FFFFFF'};
  height: ${props => (props.height ? props.height : 'auto')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0px')};
`

function Card(props) {
  return (
    <Wrapper
      id="card"
      backgroundColor={props.backgroundColor}
      height={props.height}
      borderRadius={props.borderRadius}
      onClick={props.onClick}
    >
      {props.children}
    </Wrapper>
  )
}

export default Card
