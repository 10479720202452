import React from 'react'
import styled from 'styled-components'
import {useField} from 'formik'
import { InputValidationIcon } from 'forms'

const Wrapper = styled.div`
  width: 100%;

  .text-input {
    padding-left: ${props => props.theme.space.m};
    width: 100%;
    height: 48px;
    border: 1px solid ${props => props.theme.colors.lightGrey};
    border-radius: 8px;
    background-color: ${props => props.disabled ? props.theme.colors.offWhite : ''};
    font-size: ${props => props.theme.space.m};
  }

  .text-input:focus {
    outline: 1px solid ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.colors.primary};
  }
`

function InputWrapper(props) {
  const [field, meta] = useField(props.name)

  // const inputElement = useRef(null);

  // useEffect(() => {
  //   if (inputElement.current && props.autoFocus) {
  //     inputElement.current.focus();
  //   }
  // }, []);

  function handleChange(event) {
    if (props.submitOnChange) {
      props.submitOnChange(event, props.handleChange, props.submitForm)
    }
  }

  let step = props.step ? props.step : 'any'

  let input

  if (props.handleChange) {
    input = (
      <input
        id={props.id}
        className="text-input"
        {...field}
        name={props.name}
        type={props.type}
        onChange={handleChange}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        onClick={props.onClick}
        max={props.max}
        step={step}
        onKeyPress={props.onKeyPress}
        // ref={inputElement}
      />
    )
  } else {
    input = (
      <input
        id={props.id}
        className="text-input"
        {...field}
        name={props.name}
        type={props.type}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        onClick={props.onClick}
        max={props.max}
        step={step}
        onKeyPress={props.onKeyPress}
        // ref={inputElement}
      />
    )
  }

  return (
    <Wrapper disabled={props.disabled}>
      {input}
      {/* <InputValidationIcon touched={meta.touched} error={meta.error} /> */}
    </Wrapper>
  )
}

export default InputWrapper
