import React from 'react'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import styled from 'styled-components'
import { Counter } from 'badges';

const Wrapper = styled.div`
  text-align: center;
  height: 100%;
  cursor: pointer;

  h3 {
    color: ${props => props.theme.colors.primary};
  }

  .card-body {
    svg {
      color: ${props => props.theme.colors.grey};
    }

    p {
      font-weight: 400;
      color: ${props => props.theme.colors.black};
      padding-top: ${props => props.theme.space.m};
    }

    #counter {
      margin-top: ${props => props.theme.space.m};
    }
  }
`

function AssociationCard(props) {
  function renderCount() {
    if (props.count) {
      return (
        <Counter count={props.count} />
      )
    }
  }

  function renderCard() {
    return(
      <Card>
        <Card.Header id="association-card-header" as="h3">{props.heading}</Card.Header>
        <Card.Body>
          {props.icon}
          <p>
            {props.description}
          </p>
          {renderCount()}
        </Card.Body>
      </Card>
    )
  }

  if (props.externalLink) {
    return (
      <Wrapper id='association-card'>
        <a href={props.externalLink} target="_blank" rel='noopener noreferer'>
          {renderCard()}
        </a>
      </Wrapper>
    )
  } else if(props.link) {
    return (
      <Wrapper id='association-card'>
        <Link to={props.link}>
          {renderCard()}
        </Link>
      </Wrapper>
    )
  } else if (props.onClick) {
    return (
      <Wrapper id='association-card' onClick={props.onClick}>
        {renderCard()}
      </Wrapper>
    )
  }
}

export default AssociationCard
