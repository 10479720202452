import React from 'react'
import styled from 'styled-components'
import { Label } from 'forms'
import { SearchWrapper } from 'forms'

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 25px;
`

function SearchInput(props) {
  let label

  if (props.label) {
    label = <Label text={props.label} name={props.name} />
  }

  return (
    <FieldWrapper id="text-input">
      {label}
      <SearchWrapper
        name={props.name}
        type={props.type}
        setFieldValue={props.setFieldValue}
      />
    </FieldWrapper>
  )
}

export default SearchInput
