import React from 'react'
import { renderLoadMoreButton } from 'queries';
import styled from 'styled-components'
import CardGroup from 'react-bootstrap/CardGroup';
import Row from 'react-bootstrap/Row';

const Wrapper = styled.div`
  .card-group {
    height: 100%;

    #index-grid-card {
      margin-bottom: ${props => props.theme.space.l};
    }

    #association-grid-card {
      margin-bottom: ${props => props.theme.space.l};
    }

    .card {
      text-align: center;
      margin-left: ${props => props.theme.space.m};
      margin-right: ${props => props.theme.space.m};
      height: 100%;
    }
  }
`

function CardGrid(props) {
  return (
    <Wrapper>
      <CardGroup>
        <Row>
          {props.children}
        </Row>
      </CardGroup>

      {renderLoadMoreButton(props.total, props.limit, props.loadMore)}
    </Wrapper>
  )
}

export default CardGrid