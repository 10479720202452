import React from 'react'
import styled from 'styled-components'
import {useField} from 'formik'
import { InputValidationIcon } from 'forms'

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  textarea {
    padding-left: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    ${'' /* height: 48px; */}
    background: ${props => props.theme.colors.white};
    border: 1px solid #b7b7b7;
    box-sizing: border-box;
    border-radius: 8px;
  }

  textarea:focus {
    outline: none;
  }

  position: relative;
`

function TextAreaWrapper(props) {
  const [field, meta] = useField(props.name)

  return (
    <Wrapper>
      <textarea
        className="text-input"
        {...field}
        name={props.name}
        type={props.type}
        rows={props.rows}
      />
      <InputValidationIcon touched={meta.touched} error={meta.error} />
    </Wrapper>
  )
}

export default TextAreaWrapper
