import update from 'immutability-helper'
import { FETCH_WORKFLOW_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { FETCH_WORKFLOW_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { FETCH_WORKFLOW_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_WORKFLOW_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_WORKFLOW_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_WORKFLOW_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_WORKFLOW_INVITATION_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_WORKFLOW_INVITATION_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_WORKFLOW_INVITATION_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_WORKFLOW_INVITATION_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_WORKFLOW_INVITATION_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_WORKFLOW_INVITATION_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_WORKFLOW_ASSIGNEE_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_WORKFLOW_ASSIGNEE_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_WORKFLOW_ASSIGNEE_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_WORKFLOW_ASSIGNEE_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_WORKFLOW_ASSIGNEE_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_WORKFLOW_ASSIGNEE_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_REMINDER_TO_WORKFLOW_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_REMINDER_TO_WORKFLOW_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_REMINDER_TO_WORKFLOW_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_REMINDER_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_REMINDER_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_REMINDER_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_REMINDER_FROM_WORKFLOW_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_REMINDER_FROM_WORKFLOW_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_REMINDER_FROM_WORKFLOW_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_COMMENT_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_COMMENT_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_COMMENT_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_COMMENT_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_COMMENT_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_COMMENT_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_CONTRIBUTION_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_CONTRIBUTION_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_CONTRIBUTION_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_REACTION_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_REACTION_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_REACTION_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_REACTION_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_REACTION_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_REACTION_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_CONVERSATION_DOCUMENT_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_CONVERSATION_DOCUMENT_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { ADD_CONVERSATION_DOCUMENT_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPLOAD_CONVERSATION_DOCUMENT_FILE_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPLOAD_CONVERSATION_DOCUMENT_FILE_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPLOAD_CONVERSATION_DOCUMENT_FILE_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_CONVERSATION_DOCUMENT_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_CONVERSATION_DOCUMENT_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_CONVERSATION_DOCUMENT_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_CONVERSATION_DOCUMENT_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_CONVERSATION_DOCUMENT_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { DELETE_CONVERSATION_DOCUMENT_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_PIPELINE_STATUS_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_PIPELINE_STATUS_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { UPDATE_PIPELINE_STATUS_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { FETCH_CONVERSATION_CONTRIBUTIONS_REQUEST } from 'v2/spas/workflow/redux/action-types.tsx'
import { FETCH_CONVERSATION_CONTRIBUTIONS_SUCCESS } from 'v2/spas/workflow/redux/action-types.tsx'
import { FETCH_CONVERSATION_CONTRIBUTIONS_FAILURE } from 'v2/spas/workflow/redux/action-types.tsx'
import { SET_LINKED_COMMENT } from 'v2/spas/workflow/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  workflow: {},
  linkedComment: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKFLOW_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_WORKFLOW_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_WORKFLOW_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        workflow: {
          $set: action.payload.workflow,
        }
      })
    }

    case UPDATE_WORKFLOW_REQUEST: {
      return update(state, {
        updateWorkflowStatus: { $set: 'requested' },
      })
    }

    case UPDATE_WORKFLOW_FAILURE: {
      return update(state, {
        updateWorkflowStatus: {
          $set: 'error',
        },
        updateWorkflowError: { $set: action.payload.error },
      })
    }

    case UPDATE_WORKFLOW_SUCCESS: {
      return update(state, {
        updateWorkflowStatus: { $set: 'success' },
        workflow: {
          name: {
            $set: action.payload.workflow.name,
          }
        }
      })
    }

    case ADD_WORKFLOW_INVITATION_REQUEST: {
      return state
    }

    case ADD_WORKFLOW_INVITATION_FAILURE: {
      return state
    }

    case ADD_WORKFLOW_INVITATION_SUCCESS: {
      return update(state, {
        workflow: {
          prospectiveMentions: {
            $set: action.payload.workflowInvitation.workflow.prospectiveMentions
          },
          prospectiveAssignees: {
            $set: action.payload.workflowInvitation.workflow.prospectiveAssignees
          },
          prospectiveInvitees: {
            $set: action.payload.workflowInvitation.workflow.prospectiveInvitees
          },
          workflowInvitations: {
            $set: action.payload.workflowInvitation.workflow.workflowInvitations
          }
        }
      })
    }

    case DELETE_WORKFLOW_INVITATION_REQUEST: {
      return state
    }

    case DELETE_WORKFLOW_INVITATION_FAILURE: {
      return state
    }

    case DELETE_WORKFLOW_INVITATION_SUCCESS: {
      return update(state, {
        workflow: {
          prospectiveMentions: {
            $set: action.payload.workflow.prospectiveMentions
          },
          prospectiveAssignees: {
            $set: action.payload.workflow.prospectiveAssignees
          },
          prospectiveInvitees: {
            $set: action.payload.workflow.prospectiveInvitees
          },
          workflowInvitations: {
            $set: action.payload.workflow.workflowInvitations
          }
        }
      })
    }

    case ADD_WORKFLOW_ASSIGNEE_REQUEST: {
      return state
    }

    case ADD_WORKFLOW_ASSIGNEE_FAILURE: {
      return state
    }

    case ADD_WORKFLOW_ASSIGNEE_SUCCESS: {
      return update(state, {
        workflow: {
          workflowAssignees: {
            $push: [action.payload.workflowAssignee]
          },
          prospectiveAssignees: {
            $set: action.payload.workflowAssignee.workflow.prospectiveAssignees
          },
        }
      })
    }

    case DELETE_WORKFLOW_ASSIGNEE_REQUEST: {
      return state
    }

    case DELETE_WORKFLOW_ASSIGNEE_FAILURE: {
      return state
    }

    case DELETE_WORKFLOW_ASSIGNEE_SUCCESS: {
      return update(state, {
        workflow: {
          workflowAssignees: {
            $set: action.payload.workflow.workflowAssignees
          },
          prospectiveAssignees: {
            $set: action.payload.workflow.prospectiveAssignees
          },
        }
      })
    }

    case ADD_REMINDER_TO_WORKFLOW_REQUEST: {
      return state
    }

    case ADD_REMINDER_TO_WORKFLOW_FAILURE: {
      return state
    }

    case ADD_REMINDER_TO_WORKFLOW_SUCCESS: {
      return update(state, {
        workflow: {
          calendarEvent: {
            $set: action.payload.calendarEvent,
          }
        },
      })
    }

    case UPDATE_REMINDER_REQUEST: {
      return state
    }

    case UPDATE_REMINDER_FAILURE: {
      return state
    }

    case UPDATE_REMINDER_SUCCESS: {
      return update(state, {
        workflow: {
          calendarEvent: {
            reminder: {
              $set: action.payload.reminder,
            }
          }
        },
      })
    }

    case DELETE_REMINDER_FROM_WORKFLOW_REQUEST: {
      return state
    }

    case DELETE_REMINDER_FROM_WORKFLOW_FAILURE: {
      return state
    }

    case DELETE_REMINDER_FROM_WORKFLOW_SUCCESS: {
      return update(state, {
        workflow: {
          calendarEvent: {
            $set: {}
          }
        }
      })
    }

    case ADD_COMMENT_REQUEST: {
      return state
    }

    case ADD_COMMENT_FAILURE: {
      return state
    }

    case ADD_COMMENT_SUCCESS: {
      return update(state, {
        workflow: {
          conversation: {
            contributions: {
              $push: [action.payload.contribution],
            },
          }
        }
      })
    }

    case UPDATE_COMMENT_REQUEST: {
      return state
    }

    case UPDATE_COMMENT_FAILURE: {
      return state
    }

    case UPDATE_COMMENT_SUCCESS: {
      let contributionIndex = state.workflow.conversation.contributions.findIndex(
        x => x.id === action.payload.contribution.id,
      )

      return update(state, {
        workflow: {
          conversation: {
            contributions: {
              [contributionIndex]: {
                $set: action.payload.contribution
              },
            },
          }
        }
      })
    }

    case DELETE_CONTRIBUTION_REQUEST: {
      return state
    }

    case DELETE_CONTRIBUTION_FAILURE: {
      return state
    }

    case DELETE_CONTRIBUTION_SUCCESS: {
      let index = state.workflow.conversation.contributions.findIndex(
        contribution => contribution.id === action.payload.id
      )

      return update(state, {
        workflow: {
          conversation: {
            contributions: {
              $splice: [[index, 1]],
            },
          }
        }
      })
    }

    case ADD_REACTION_REQUEST: {
      return state
    }

    case ADD_REACTION_FAILURE: {
      return state
    }

    case ADD_REACTION_SUCCESS: {
      let contributionIndex = state.workflow.conversation.contributions.findIndex(
        x => x.id === action.payload.comment.contribution.id,
      )

      return update(state, {
        workflow: {
          conversation: {
            contributions: {
              [contributionIndex]: {
                comment: {
                  $set: action.payload.comment,
                },
              },
            },
          }
        }
      })
    }

    case DELETE_REACTION_REQUEST: {
      return state
    }

    case DELETE_REACTION_FAILURE: {
      return state
    }

    case DELETE_REACTION_SUCCESS: {
      let contributionIndex = state.workflow.conversation.contributions.findIndex(
        x => x.id === action.payload.comment.contribution.id,
      )

      return update(state, {
        workflow: {
          conversation: {
            contributions: {
              [contributionIndex]: {
                comment: {
                  $set: action.payload.comment,
                },
              },
            },
          }
        }
      })
    }

    case ADD_CONVERSATION_DOCUMENT_REQUEST: {
      return state
    }

    case ADD_CONVERSATION_DOCUMENT_FAILURE: {
      return state
    }

    case ADD_CONVERSATION_DOCUMENT_SUCCESS: {
      return update(state, {
        workflow: {
          conversation: {
            contributions: {
              $push: [action.payload.contribution],
            },
          }
        }
      })
    }

    case UPDATE_CONVERSATION_DOCUMENT_REQUEST: {
      return state
    }

    case UPDATE_CONVERSATION_DOCUMENT_FAILURE: {
      return state
    }

    case UPDATE_CONVERSATION_DOCUMENT_SUCCESS: {
      let contributionIndex = state.workflow.conversation.contributions.findIndex(
        x => x.id === action.payload.contribution.id,
      )

      return update(state, {
        workflow: {
          conversation: {
            contributions: {
              [contributionIndex]: {
                $set: action.payload.contribution
              },
            },
          }
        }
      })
    }

    case UPLOAD_CONVERSATION_DOCUMENT_FILE_REQUEST: {
      return state
    }

    case UPLOAD_CONVERSATION_DOCUMENT_FILE_FAILURE: {
      return state
    }

    case UPLOAD_CONVERSATION_DOCUMENT_FILE_SUCCESS: {
      let contributionIndex = state.workflow.conversation.contributions.findIndex(
        contribution => contribution.id === action.payload.contributionId,
      )

      return update(state, {
        workflow: {
          conversation: {
            contributions: {
              [contributionIndex]: {
                document: {
                  $set: action.payload.document,
                },
              },
            },
          }
        }
      })
    }

    case DELETE_CONVERSATION_DOCUMENT_REQUEST: {
      return state
    }

    case DELETE_CONVERSATION_DOCUMENT_FAILURE: {
      return state
    }

    case DELETE_CONVERSATION_DOCUMENT_SUCCESS: {
      return Object.assign({}, state, {
        networkRequestStatus: 'success',
      })
    }

    case UPDATE_PIPELINE_STATUS_REQUEST: {
      return state
    }

    case UPDATE_PIPELINE_STATUS_FAILURE: {
      return state
    }

    case UPDATE_PIPELINE_STATUS_SUCCESS: {
      return update(state, {
        workflow: {
          pipeline: {
            $set: action.payload.pipeline,
          },
        }
      })
    }

    case SET_LINKED_COMMENT: {
      return update(state, {
        linkedComment: {
          $set: action.payload
        },
      })
    }

    case FETCH_CONVERSATION_CONTRIBUTIONS_REQUEST: {
      return update(state, {
        fetchConversationContributionsStatus: { $set: 'requested' },
      })
    }

    case FETCH_CONVERSATION_CONTRIBUTIONS_FAILURE: {
      return update(state, {
        fetchConversationContributionsStatus: {
          $set: 'error',
        },
        fetchConversationContributionsError: { $set: action.payload.error },
      })
    }

    case FETCH_CONVERSATION_CONTRIBUTIONS_SUCCESS: {
      return update(state, {
        // fetchConversationContributionsStatus: { $set: 'success' },
        workflow: {
          conversation: {
            contributions: {
              $set: action.payload.contributions,
            },
          }
        }
      })
    }

    default:
      return state
  }
}