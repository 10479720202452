import React from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: ${props => props.theme.space.m};
`

function FormModalBody(props) {
  return (
    <Modal.Body>
      <Wrapper>{props.children}</Wrapper>
    </Modal.Body>
  )
}

export default FormModalBody
