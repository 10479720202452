import React from 'react'
import { useCurrentAccountUser } from 'functions'
import UserTopNavbar from 'sharedComponents/UserTopNavbar/UserTopNavbar.js'
import {PublicTopNavbar} from 'navs'

function TopNavbar() {
  const currentAccountUser = useCurrentAccountUser()

  if (currentAccountUser.id) {
    return <UserTopNavbar />
  } else {
    return <PublicTopNavbar />
  }
}

export default TopNavbar
