import React from 'react'
import { useCurrentAccount } from 'functions'
import styled from 'styled-components'

const CoverWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: '#ddd';
  opacity: ${props => props.$opacity ? props.$opacity : 0.4};
  display: ${props => props.$disabled ? 'block' : 'inline'};
  pointer-events: none;
`

const CoverDisabled = ({ opacity, children }) => {
  const currentAccount = useCurrentAccount();

  const subscription = currentAccount.subscription || {}
  const status = subscription.status || ''
  const disabled = [
    'canceled',
    'incomplete_expired',
    'incomplete',
    'trial_has_ended',
    'unpaid',
    'past_due',
  ].includes(status)

  if (disabled) return <CoverWrapper $disabled={disabled} $opacity={opacity}>{children}</CoverWrapper>;
  return <React.Fragment>{children}</React.Fragment>
};

export default CoverDisabled;