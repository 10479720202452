import React from 'react'
import { Navbar } from 'navs'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import {Brand} from 'navs'
import Links from './links.js'
// import MobileSidebar from 'sharedComponents/MobileSidebar/MobileSidebar.js'
import { RenderInDevice } from 'functional-components'

const Wrapper = styled.div`
  #navbar {
    padding-left: ${props => props.theme.space.l};
    padding-right: ${props => props.theme.space.l};
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
  }
`

function MortgageBrokerTopNavbar(props) {
  return (
    <Wrapper>
      <Navbar backgroundColor={props => props.theme.colors.blue}>
        <Row>
          <Col sm="6" xs="6">
            <Brand />
          </Col>

          <RenderInDevice devices={['mobile', 'tablet', 'laptop']}>
            <Col sm="5" xs="5">
              {/* <Search /> */}
            </Col>
            <Col sm="1" xs="1">
              {/* <MobileSidebar navbarTheme={props.navbarTheme} /> */}
            </Col>
          </RenderInDevice>

          <RenderInDevice devices={['desktop']}>
            <Col sm="6" xs="6">
              <Links />
            </Col>
          </RenderInDevice>
        </Row>
      </Navbar>
    </Wrapper>
  )
}

export default MortgageBrokerTopNavbar
