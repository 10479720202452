import React from 'react'
import { Helmet } from "react-helmet-async"

function ZendeskWidget() {
  // return (
  //   <Helmet>
  //     <script id="ze-snippet"
  //       src="https://static.zdassets.com/ekr/snippet.js?key=2741bc59-f398-43ed-afb6-50d637eb2598">
  //     </script>
  //   </Helmet>
  // )

  return null
}


export default ZendeskWidget

