import React from 'react'
import styled from 'styled-components'
import { QuestionCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'
import { useCurrentAccount } from 'functions'

const IconWrapper = styled.div`
  height: 100%;
  justify-content: flex-end;

  display: flex;
  align-items: center;

  color: ${props => props.theme.colors.white};
  padding-right: 25px;
  cursor: pointer;

	a {
		text-decoration: none;
		color: white;
	}

  a:hover {
    text-decoration: none;
		color: white;
  }
`

function SupportIcon(props) {

  const currentAccount = useCurrentAccount()

  let knowledgeBase = currentAccount.knowledgeBase || {}

  return (
    <IconWrapper aria-haspopup="true">
      <Link to={`/knowledge-bases/${knowledgeBase.id}`}>
        <QuestionCircle size={22.5} />
      </Link>
    </IconWrapper>
  )
}

export default SupportIcon
