import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { LabelWrapper } from 'forms'
import {TwitterPicker} from 'react-color'

const FieldWrapper = styled.div`
  padding-bottom: ${props => props.theme.space.l};
`

function ColourInput(props) {
  const [colour, setColour] = useState(props.value)

  function handleChangeColour(color) {
    setColour(color.hex)
    props.setFieldValue(props.name, color.hex)
  }

  return (
    <FieldWrapper id="colour-input">
      <LabelWrapper
        label={props.label}
        name={props.name}
        toolTipContent={
          props.toolTipContent +
          ' You can either select a colour from the list or supply a hex value.'
        }
      />
      <TwitterPicker color={colour} onChangeComplete={handleChangeColour} />
    </FieldWrapper>
  )
}

export default ColourInput
