import React from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { XCircleFill } from 'react-bootstrap-icons';

const ModalBodyWrapper = styled.div`
  text-align: center;
`

const IconWrapper = styled.div`
  padding-top: 50px;
  svg {
    color: ${props => props.theme.colors.red};
    height: 100px;
    width: 100px;
  }
`

const ModalHeading = styled.div`
  padding-top: 30px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;

  color: ${props => props.theme.colors.black};
`

const ModalDescription = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  p {
    margin: 2.5px;
  }

  color: ${props => props.theme.colors.black};
`

const ModalFooterWrapper = styled.div`
  .modal-footer {
    display: flex;
    justify-content: center;
  }
`

const ContactUs = styled.div`
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: ${props => props.theme.colors.grey};

  padding-top: 15px;
  padding-bottom: 10px;
`

const ContactOption = styled.div`
  padding-top: 2.5px;
`

const ChildrenWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ContactUsWrapper = styled.div`
  width: 100%;
`

function FailureModal(props) {
  let description = (
    <div>
      <p>Something's went wrong</p>
      <p>If the problem persists - please contact us for support</p>
    </div>
  )

  function contactUs() {
    return (
      <ContactUs>
        If you need support - please contact us in one of the following ways:
        <ContactOption>
          • Click the support icon in the bottom corner
        </ContactOption>
        <ContactOption>
          • Ask a question in our{' '}
          <a
            href="https://www.facebook.com/groups/439931070312581"
            target="_blank"
            rel="noreferrer"
          >
            Facebook support group
          </a>
        </ContactOption>
        <ContactOption>• Send an email to hello@stacked.group</ContactOption>
      </ContactUs>
    )
  }

  function footer() {
    if (props.children) {
      return (
        <ModalFooterWrapper>
          <Modal.Footer>
            <ChildrenWrapper>{props.children}</ChildrenWrapper>
            <ContactUsWrapper>{contactUs()}</ContactUsWrapper>
          </Modal.Footer>
        </ModalFooterWrapper>
      )
    } else {
      return (
        <ModalFooterWrapper>
          <Modal.Footer>{contactUs()}</Modal.Footer>
        </ModalFooterWrapper>
      )
    }
  }

  return (
    <Modal centered show={props.show} size="lg">
      <ModalBodyWrapper>
        <Modal.Body>
          <IconWrapper>
            <XCircleFill />
          </IconWrapper>
          <ModalHeading>{props.heading || 'Oooooops!'}</ModalHeading>
          <ModalDescription>
            {props.description || description}
          </ModalDescription>
        </Modal.Body>
        {footer()}
      </ModalBodyWrapper>
    </Modal>
  )
}

export default FailureModal
