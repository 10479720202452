import {SESSION_REQUEST} from 'redux/actionTypes.js'
import {SESSION_SUCCESS} from 'redux/actionTypes.js'
import {SESSION_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function session() {
  return function (dispatch) {
    dispatch(sessionRequest())

    let query = `
      {
        currentUser {
          sessionExpiring
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          dispatch(sessionFailure(res.data.errors[0].message))
        } else {
          dispatch(sessionSuccess(res.data.data.currentUser.sessionExpiring))

          if (res.data.data.currentUser.sessionExpiring) {
            Cookies.remove('jwtToken')
          }
        }
      })
      .catch(error => {
        dispatch(sessionFailure(error.message))
      })
  }
}

export const sessionRequest = () => ({
  type: SESSION_REQUEST,
})

export const sessionSuccess = sessionExpiring => ({
  type: SESSION_SUCCESS,
  payload: {
    sessionExpiring: sessionExpiring,
  },
})

export const sessionFailure = error => ({
  type: SESSION_FAILURE,
  payload: {
    error: error,
  },
})
