import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding-bottom: ${props => props.theme.space.xl};

  hr {
    padding: 0px;
    margin: 0px;
  }

  @media ${props => props.theme.devices.mobile} {
    padding-left: ${props => props.theme.space.m};
    padding-right: ${props => props.theme.space.m};
  }

  @media ${props => props.theme.devices.tablet} {
    padding-left: ${props => props.theme.space.m};
    padding-right: ${props => props.theme.space.m};
  }

  @media ${props => props.theme.devices.laptop} {
    padding-left: ${props => props.theme.space.l};
    padding-right: ${props => props.theme.space.l};
  }

  @media ${props => props.theme.devices.desktop} {
    padding-left: ${props => props.theme.space.l};
    padding-right: ${props => props.theme.space.l};
  }
`

function PageCard(props) {
  return (
    <Wrapper id="page-card">
      {props.children}
    </Wrapper>
  )
}

export default PageCard
