import React from 'react'
import { useDevice } from 'functions'

const RenderInDevice = props => {
  const device = useDevice()
  const devices = props.devices || []

  if (devices.includes(device)) {
    return <>{props.children}</>
  } else {
    return null
  }
}

export default RenderInDevice
