import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import { ChevronDown } from 'react-bootstrap-icons'
import Grow from '@material-ui/core/Grow'
import Poppers from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const Wrapper = styled.div`

`

const Icon = styled(ChevronDown)`
  color: ${props => props.theme.colors.white} !important;
`

const DropdownButton = props => {
  let open = props.open 
  let setOpen = props.setOpen

  const handleClickButton = event => {
    if (open && open.contains(event.target)) {
      setOpen(null)
    } else {
      setOpen(event.currentTarget)
    }
  }

  const handleClose = () => {
    setOpen(null)
  }

  return (
    <>
      <Wrapper>
        <Button
          content={<span>{props.buttonText} <Icon /></span>}
          size={props => props.theme.space.xs} 
          aria-haspopup="true"
          onClick={handleClickButton}
        />
      </Wrapper>

      <Poppers
        open={Boolean(open)}
        anchorEl={open}
        transition
        placement="center"
      >
        {({TransitionProps}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <div>{props.children}</div>
            </ClickAwayListener>
          </Grow>
        )}
      </Poppers>
    </>
  )
}

export default DropdownButton
