import React from 'react'
import styled from 'styled-components'
import { SelectWrapperWithValue } from 'forms'
import { LabelWrapper } from 'forms'

const FieldWrapper = styled.div`
  padding-bottom: ${props => props.theme.space.l};
`

function SelectInput(props) {
  return (
    <FieldWrapper id="text-input">
      <LabelWrapper
        array={props.array}
        label={props.label}
        name={props.name}
        toolTipContent={props.toolTipContent}
        required={props.required}
      />
      <SelectWrapperWithValue
        name={props.name}
        type={props.type}
        options={props.options}
        submitForm={props.submitForm}
        handleChange={props.handleChange}
        submitOnChange={props.submitOnChange}
        value={props.value}
      />
    </FieldWrapper>
  )
}

export default SelectInput
