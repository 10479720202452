import React from 'react'
import styled from 'styled-components'
import Dropdown from 'react-bootstrap/Dropdown';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.theme.space.l};

  svg {
    margin-left: ${props => props.theme.space.m};
  }

  #filter-dropdown {
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.black};
    box-shadow: none;
  }
`

function Filter(props) {
  let renderOptions = props.options.map(function (option) {
    return (
      <Dropdown.Item 
        key={option.value} 
        onClick={() => props.applyFilter(option.value)} 
      >
        {option.name}
      </Dropdown.Item>
    )
  })

  return (
    <Wrapper id='filter'>
      <Dropdown>
        <Dropdown.Toggle id="filter-dropdown">
          Filter
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => props.applyFilter('')} >
            All
          </Dropdown.Item>
          {renderOptions}
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  )
}

export default Filter
