import React from 'react'
import { ThemeProvider } from 'styled-components'

const size = {
  mobile: 576,
  tablet: 768,
  laptop: 992,
  desktop: 1200,
}

const theme = {
  colors: {
    primary: '#2A3E87',
    secondary: '#f6fdf8',
    white: '#FFFFFF',
    black: '#000000',
    grey: '#6C727F',
    lightGrey: '#999999',
    green: '#4DC37C',
    lightGreen: '#4CC07A',
    offWhite: '#F5F7F9',
    orange: '#E8B34D',
    red: '#EC0B0B',
    aquaBlue: '#54c3c9',
    blue: '#2A3E87',
    royalBlue: '#425393',
    pastelRed: '#D25A5A',
    translucentRed: 'rgb(255, 0, 0, 0.1)',
    translucentOrange: 'rgb(255, 165, 0, 0.1)'
  },
  devices: {
    smallMobile: `(min-width: 0px) and (max-width: 320px)`,
    mobile: `(min-width: 0px) and (max-width: ${size.mobile}px)`,
    tablet: `(min-width: ${size.mobile}px) and (max-width: ${size.tablet}px)`,
    laptop: `(min-width: ${size.tablet}px) and (max-width: ${size.laptop}px)`,
    desktop: `(min-width: ${size.laptop}px)`,
  },
  space: {
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '32px',
    xl: '64px',
    xxl: '128px',
  }
}

const MortgageBrokerTheme = ({ children }) => {
  let appTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: theme.colors.primary
    }
  }

  return (
    <ThemeProvider theme={appTheme}>
      {children}
    </ThemeProvider>
  )
}

export default MortgageBrokerTheme
