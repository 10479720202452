import React from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {HideModalIcon} from 'modals'
import {RecompileDataButton} from 'buttons'
import {DataVisualisationButtons} from 'buttons'

const Heading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  padding-top: 25px;
  padding-bottom: 25px;

  background: #f8f8f9;
  border-radius: 8px;
`

const HeadingText = styled.div`
  padding-left: 25px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;

  color: #1a293a;
`

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;

  #button {
    #button-content {
      padding-top: 2.5px;
      padding-bottom: 2.5px;
      padding-left: 5px;
      padding-right: 5px;
    }

    #button-text {
      padding-left: 5px;
      font-size: 12px;
    }
  }

  #hide-modal-icon {
    display: flex;
    align-self: center;
    padding-right: 0px;
    padding-left: 10px;
  }

  @media ${props => props.theme.devices.desktop} {
    padding-right: 50px;

    #hide-modal-icon {
      padding-left: 25px;
    }

    #button {
      #button-content {
        padding-left: 10px;
        padding-right: 10px;
      }
      #button-text {
        padding-left: 10px;
        fontsize: 14px;
      }
    }
  }
`

const Headings = styled.div`
  display: flex;
  width: 100%;

  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5%;
  padding-right: 5%;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: #1a293a;
`

function DealFinancialsModalHeader(props) {
  let visualise = props.visualise

  let recompileButton

  if (props.recompile) {
    recompileButton = <RecompileDataButton recompile={props.recompile} />
  }

  let headings

  if (props.children) {
    headings = <Headings>{props.children}</Headings>
  }

  const columns = props.columns || []
  const headingCol = columns[0] || {}
  const actionCol = columns[1] || {}
  return (
    <Modal.Header>
      <Row>
        <Heading>
          <Col sm={headingCol.sm || 6} xs={headingCol.xs || 6}>
            <HeadingText>{props.heading}</HeadingText>
          </Col>

          <Col sm={actionCol.sm || 6} xs={actionCol.xs || 6}>
            <Action>
              <DataVisualisationButtons
                visualise={visualise}
                setVisualise={props.setVisualise}
              />
              {recompileButton}
              <HideModalIcon setShow={props.setShow} />
            </Action>
          </Col>
        </Heading>

        {headings}
      </Row>
    </Modal.Header>
  )
}

export default DealFinancialsModalHeader
