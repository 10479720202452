import React, { useState } from 'react'
import styled from 'styled-components'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import PropTypes from 'prop-types'
import {VideoModal} from 'modals'

const Wrapper = styled.div`
  svg {
    cursor: pointer;
    height: 17.5px;
    width: 17.5px;
    color: ${props => props.theme.colors.grey};
  }
`

function VideoToolTip(props) {
  const [show, setShow] = useState(false)

  return (
    <Wrapper id="tooltip">
      <OndemandVideoIcon onClick={() => setShow(true)} />

      <VideoModal
        show={show}
        setShow={setShow}
        size="xl"
        heading={props.heading}
      >
        <iframe
          src={props.url}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </VideoModal>
    </Wrapper>
  )
}

export default VideoToolTip

VideoToolTip.propTypes = {
  content: PropTypes.string.isRequired,
}
