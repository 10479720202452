import React from 'react'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup';
import styled from 'styled-components'

const CardWrapper = styled.div`
  height: 100%;

  .card {
    color: ${props => props.theme.colors.grey};

    .card-header {
      color: ${props => props.theme.colors.primary};
    }

    .card-body {
      padding: 0px;
    }

    svg {
      height: 100%;
      padding: ${props => props.theme.space.m};
    }
  }
`

function IndexGridCard(props) {
  function renderListGroupItems() {
    if (props.listGroupItems) {
      return (
        <ListGroup variant="flush">
          {props.listGroupItems.map(function (listGroupItem) {
            return (
              <ListGroup.Item key={listGroupItem.key}>
                {listGroupItem.value}
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      )
    }
  }


  return (
    <Col xs='6' lg='3' id='index-grid-card'>
      <Link to={props.link}>
        <CardWrapper>
          <Card>
            <Card.Header as="h3">{props.heading}</Card.Header>
            <Card.Body>
              {props.children}
            </Card.Body>
            {renderListGroupItems()}
          </Card>
        </CardWrapper>
      </Link>
    </Col>
  )
}

export default IndexGridCard