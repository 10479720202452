import React from 'react'
import { LabelWrapper } from 'forms'
import styled from 'styled-components'
import Select from 'react-select';

const Wrapper = styled.div`
  padding-bottom: ${props => props.theme.space.l};

  input {
    height: 36px;
  }
`

function MultiSelect(props) {
  function handleChange(selectedOptions) {
    let values = props.options.map(option => option.value)
    let selectedValues = selectedOptions.map(option => option.value)

    if (selectedValues.includes('Select all')) {
      props.setValue(props.options)
      props.setFieldValue(props.name, values)
    } else {
      props.setValue(selectedOptions)
      props.setFieldValue(props.name, selectedValues) 
    }
  }

  function options() {
    if (props.disableSelectAll) {
      return props.options
    } else {
      return [{ label: 'Select all', value: 'Select all' }, { label: '--------', value: '' }, ...props.options]
    }
  }

  return (
    <Wrapper>
      <LabelWrapper
        label={props.label}
        name={props.name}
        toolTipContent={props.toolTipContent}
      />
        <Select
          onChange={handleChange}
          isMulti
          options={options()}
          defaultValue={props.defaultValue}
          value={props.value}
          styles={{
            menu: provided => ({ ...provided, zIndex: 2 }),
          }}
        />
    </Wrapper>
  )
}

export default MultiSelect
