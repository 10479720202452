import { addNewComment } from 'functions'

function processNewComment({
  values: values,
  resetForm: resetForm,
  setSubmitting: setSubmitting,
  setShowConfirmationModal: setShowConfirmationModal,
  addComment: addComment,
  conversationId: conversationId,
  contributionId: contributionId,
  handleCreate: handleCreate,
  tiptapEditorRef: tiptapEditorRef
}) {
  if (values.recipientIds.length === 0) {
    setSubmitting(false)
    setShowConfirmationModal(true)
  } else {
    addNewComment({
      values: values,
      resetForm: resetForm,
      addComment: addComment,
      conversationId: conversationId,
      contributionId: contributionId,
      handleCreate: handleCreate,
      setShowConfirmationModal: setShowConfirmationModal,
      tiptapEditorRef: tiptapEditorRef
    })
  }
}

export default processNewComment
