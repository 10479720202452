function addNewComment({
  values: values,
  resetForm: resetForm,
  addComment: addComment,
  conversationId: conversationId,
  contributionId: contributionId,
  handleCreate: handleCreate,
  setShowConfirmationModal: setShowConfirmationModal,
  tiptapEditorRef: tiptapEditorRef
}) {
  addComment(
    conversationId,
    contributionId,
    values,
    handleCreate,
  )

  setShowConfirmationModal(false)
  resetForm()
  tiptapEditorRef.current && tiptapEditorRef.current.clearContent()
}

export default addNewComment