import update from 'immutability-helper'
import {SESSION_REQUEST} from 'redux/actionTypes.js'
import {SESSION_FAILURE} from 'redux/actionTypes.js'
import {SESSION_SUCCESS} from 'redux/actionTypes.js'
import {LOGIN_REQUEST} from 'redux/actionTypes.js'
import {LOGIN_FAILURE} from 'redux/actionTypes.js'
import {LOGIN_SUCCESS} from 'redux/actionTypes.js'
import {LOGOUT_REQUEST} from 'redux/actionTypes.js'
import {LOGOUT_FAILURE} from 'redux/actionTypes.js'
import {LOGOUT_SUCCESS} from 'redux/actionTypes.js'
import { SIGN_IN_WITH_MAGIC_LINK_REQUEST } from 'redux/actionTypes.js'
import { SIGN_IN_WITH_MAGIC_LINK_FAILURE } from 'redux/actionTypes.js'
import { SIGN_IN_WITH_MAGIC_LINK_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  sessionStatus: 'requested',
  sessionError: '',
  sessionExpiring: false,
  fetchPromotionCodeStatus: 'requested',
  fetchPromotionCodeError: '',
  promotionCode: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SESSION_REQUEST: {
      return update(state, {
        sessionStatus: {$set: 'requested'},
      })
    }

    case SESSION_FAILURE: {
      return update(state, {
        sessionStatus: {
          $set: 'error',
        },
        sessionError: {$set: action.payload.error},
      })
    }

    case SESSION_SUCCESS: {
      return update(state, {
        sessionStatus: {$set: 'success'},
        sessionExpiring: {
          $set: action.payload.sessionExpiring,
        },
      })
    }

    case LOGIN_REQUEST: {
      return state
    }

    case LOGIN_FAILURE: {
      return state
    }

    case LOGIN_SUCCESS: {
      return state
    }

    case LOGOUT_REQUEST: {
      return state
    }

    case LOGOUT_FAILURE: {
      return state
    }

    case LOGOUT_SUCCESS: {
      return state
    }

    case SIGN_IN_WITH_MAGIC_LINK_REQUEST: {
      return state
    }

    case SIGN_IN_WITH_MAGIC_LINK_FAILURE: {
      return state
    }

    case SIGN_IN_WITH_MAGIC_LINK_SUCCESS: {
      return state
    }

    default:
      return state
  }
}
