import { useCurrentUser } from 'functions'

function useConsented() {
  const currentUser = useCurrentUser()

  return currentUser.consentedToProcessPropertyInvestmentDataAt !== null
    ? true
    : false
}

export default useConsented
