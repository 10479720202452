import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import {Link} from 'react-router-dom'

const Wrapper = styled.div`
  padding-top: 25px;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: center;
  #button {
    border-radius: 8px;

    #button-content {
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
`

function ReturnToSafetyButton(props) {
  return (
    <Wrapper>
      <Link to="/">
        <Button content="Return to safety" fontSize="18px" />
      </Link>
    </Wrapper>
  )
}

export default ReturnToSafetyButton
