function processMutationSuccess({
  dispatch: dispatch,
  handler: handler,
  enqueueSnackbar: enqueueSnackbar,
  redirectUrl: redirectUrl,
  object: object,
  message: message,
  showModal: showModal,
}) {
  dispatch(handler({ object: object }))

  if (enqueueSnackbar) {
    enqueueSnackbar(message)
  }

  if (showModal) {
    showModal(false)
  }

  if (redirectUrl) {
    window.location.href = redirectUrl
  }
}

export default processMutationSuccess