import React from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import {HideModalIcon} from 'modals'
import PropTypes from 'prop-types'

const ModalHeaderWrapper = styled.div`
  .modal-header {
    padding-top: ${props => props.theme.space.m} !important;
    padding-bottom: ${props => props.theme.space.m} !important;
    padding-left: ${props => props.theme.space.l} !important;
    padding-right: ${props => props.theme.space.l} !important;
    font-weight: bold;

    @media ${props => props.theme.devices.desktop} {
      padding-left: ${props => props.theme.space.xl} !important;
      padding-right: ${props => props.theme.space.xl} !important;
    }
  }
`

const ModalBodyWrapper = styled.div`
  padding-top: ${props => props.theme.space.l} !important;
  padding-bottom: ${props => props.theme.space.l}  !important;
  padding-left: ${props => props.theme.space.l} !important;
  padding-right: ${props => props.theme.space.l} !important;

  @media ${props => props.theme.devices.desktop} {
    padding-left: ${props => props.theme.space.xl} !important;
    padding-right: ${props => props.theme.space.xl} !important;
  }

  #text-input {
    padding-right: ${props => props.theme.space.s};
  }

  #select-input {
    padding-right: ${props => props.theme.space.s};
  }
`

function FormModal(props) {
  let size = props.size ? props.size : 'xl'

  let header

  if (props.heading) {
    header = (
      <ModalHeaderWrapper>
        <Modal.Header>
          {props.heading}
          <HideModalIcon setShow={props.setShow} />
        </Modal.Header>
      </ModalHeaderWrapper>
    )
  }

  function renderContents() {
    if (props.granular) {
      return <div>{props.children}</div>
    } else {
      return (
        <div>
          {header}
          <ModalBodyWrapper>
            <Modal.Body>{props.children}</Modal.Body>
          </ModalBodyWrapper>
        </div>
      )
    }
  }

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => props.setShow(false)}
      size='xl'
    >
      {renderContents()}
    </Modal>
  )
}

export default FormModal

FormModal.propTypes = {
  size: PropTypes.string,
  heading: PropTypes.string,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
}
