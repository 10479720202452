function processMutationError({
  dispatch: dispatch,
  handler: handler,
  enqueueSnackbar: enqueueSnackbar,
  error: error,
  showModal: showModal,
}) {
  dispatch(handler({error: error}))
  enqueueSnackbar(error, { variant: 'error' })

  if (showModal) {
    showModal(false)
  }
}

export default processMutationError