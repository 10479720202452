import React from 'react'

function ExternalLink(props) {
  return (
    <a href={props.href} target='_blank' rel='noopener noreferer'>
      {props.text}
    </a>
  )
}

export default ExternalLink