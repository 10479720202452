import React from 'react'
import styled from 'styled-components'
import { BoxArrowUpRight } from 'react-bootstrap-icons'
import Button from './Button'

const ButtonWrapper = styled.div`
  padding-right: 15px;

  #button-content {
    display: flex;
    justify-content: center;
    align-items: center;

    #button-text {
      padding-left: 5px;
    }
  }

  svg {
    height: 20px;
    width: 20px;
  }
`

function ViewDocumentButton(props) {
  return (
    <ButtonWrapper>
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        <Button
          content={<span><BoxArrowUpRight /> View Document</span>}
          size={props => props.theme.space.s}
        />
      </a>
    </ButtonWrapper>
  )
}

export default ViewDocumentButton
