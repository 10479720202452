import React from 'react'
import Container from 'react-bootstrap/Container'
import { Page } from 'v2'
import {TopNavbar} from 'navs'
import { PageCard } from 'pages'
import styled from 'styled-components'

const Wrapper = styled.div`
  table {
    margin-bottom: ${props => props.theme.space.l};
  }

  #column-heading {
    font-weight: 800;
  }
`

function ViewPage(props) {
  return (
    <Container fluid>
      <TopNavbar />
      <Page>
        <PageCard>
          <Wrapper>
            {props.children}
          </Wrapper>
        </PageCard>
      </Page>
    </Container>
  )
}

export default ViewPage
