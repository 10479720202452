import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  
  h2 {
    padding-top: ${props => props.theme.space.l};
    padding-bottom: ${props => props.theme.space.l};
    color: ${props => props.theme.colors.primary};
  }
`

function Heading(props) {
  return (
    <Wrapper>
      <h2>{props.text}</h2>
    </Wrapper>
  )
}

export default Heading
