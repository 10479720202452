export const FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_REQUEST = 'FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_REQUEST'
export const FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_SUCCESS = 'FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_SUCCESS'
export const FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_FAILURE = 'FETCH_ACCOUNT_USER_ACTIVITY_FEED_ITEMS_FAILURE'

export const ARCHIVE_ACTIVITY_FEED_ITEM_REQUEST = 'ARCHIVE_ACTIVITY_FEED_ITEM_REQUEST'
export const ARCHIVE_ACTIVITY_FEED_ITEM_SUCCESS = 'ARCHIVE_ACTIVITY_FEED_ITEM_SUCCESS'
export const ARCHIVE_ACTIVITY_FEED_ITEM_FAILURE = 'ARCHIVE_ACTIVITY_FEED_ITEM_FAILURE'

export const UNARCHIVE_ACTIVITY_FEED_ITEM_REQUEST = 'UNARCHIVE_ACTIVITY_FEED_ITEM_REQUEST'
export const UNARCHIVE_ACTIVITY_FEED_ITEM_SUCCESS = 'UNARCHIVE_ACTIVITY_FEED_ITEM_SUCCESS'
export const UNARCHIVE_ACTIVITY_FEED_ITEM_FAILURE = 'UNARCHIVE_ACTIVITY_FEED_ITEM_FAILURE'

export const DELETE_ACTIVITY_FEED_ITEM_REQUEST = 'DELETE_ACTIVITY_FEED_ITEM_REQUEST'
export const DELETE_ACTIVITY_FEED_ITEM_SUCCESS = 'DELETE_ACTIVITY_FEED_ITEM_SUCCESS'
export const DELETE_ACTIVITY_FEED_ITEM_FAILURE = 'DELETE_ACTIVITY_FEED_ITEM_FAILURE'