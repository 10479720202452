import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {useAuthenticated, useConsented} from 'functions'
import {NotAuthorised, MainWrapper} from 'functional-components'

function PrivateRoute({children, ...rest}) {
  const authenticated = useAuthenticated()
  const authorised = rest.authorised
  const consented = useConsented()

  function renderRoute(location) {
    if (!authenticated) {
      return <Redirect to={{pathname: '/login', state: {from: location}}} />
    } else if (!authorised) {
      return <NotAuthorised />
    // } else if (!consented && window.location.pathname !== '/profile') {
    //   return <Redirect to={{pathname: '/profile', state: {from: location}}} />
    } else {
      return <MainWrapper>{children}</MainWrapper>
    }
  }

  return <Route {...rest} render={({location}) => renderRoute(location)} />
}

export default PrivateRoute
