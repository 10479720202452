import React from 'react'
import styled from 'styled-components'
import Button from './Button'

const Wrapper = styled.div`
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '15px')};

  #button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;

    #button-text {
      padding-left: 5px;
    }
  }
`

function ResourceButton(props) {
  return (
    <Wrapper>
      <Button 
        {...props}
        backgroundColor={props => props.theme.colors.offWhite} 
        color={props => props.theme.colors.black} 
        fontWeight="bold"
      />
    </Wrapper>
  )
}

export default ResourceButton
