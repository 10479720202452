import React from 'react'
import styled from 'styled-components'

const Text = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.space.m};
  padding-bottom: ${props => props.theme.space.s};
  text-align: left;

  color: ${props => (props.color ? props.color : props.theme.colors.black)};
`

const Wrapper = styled.div`
  display: flex;

  #error-message {
    font-size: 12px;
    padding-left: ${props => props.theme.space.m};
    color: ${props => props.theme.colors.red};
  }
`

function LabelWithoutErrorMessage(props) {
  return (
    <Wrapper id="label">
      <Text id="label-text">{props.text}</Text>
    </Wrapper>
  )
}

export default LabelWithoutErrorMessage
