import React from 'react'
import { LoadMoreButton } from 'buttons'

function renderLoadMoreButton(total, limit, loadMore) {
  if (total > limit) {
    return (
      <LoadMoreButton
        total={total}
        limit={limit}
        text='Load more'
        loadMore={loadMore}
      />
    )
  }
}

export default renderLoadMoreButton