import React from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import {HideModalIcon} from 'modals'
import Col from 'react-bootstrap/Col'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: bold;

  padding-bottom: ${props => props.theme.space.m};

  #button {
    margin-left: ${props => props.theme.space.m}
  }
`

const ModalHeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  #hide-modal-icon {
    padding-top: ${props => props.theme.space.s};
    padding-left: ${props => props.theme.space.m};
  }
`

function FormModalHeader(props) {
  return (
    <Modal.Header>
      <Wrapper>
        <Col xs="6">
          <h3>{props.heading}</h3>
        </Col>
        <Col xs="6">
          <ModalHeaderActions>
            {props.children}
            <HideModalIcon setShow={props.setShow} />
          </ModalHeaderActions>
        </Col>
      </Wrapper>
    </Modal.Header>
  )
}

export default FormModalHeader
