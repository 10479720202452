import axios from 'axios'
import Cookies from 'js-cookie'

function fetchFormattedAddress(address, setFieldValue, setCountry) {
  return function (dispatch) {
    // dispatch(fetchFormattedAddressRequest())

    return axios({
      url: process.env.REACT_APP_API_PATH + 'populate_address_fields',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        address_components: address,
      },
    })
      .then(res => {
        if (res.data.errors) {
          // dispatch(fetchFormattedAddressFailure(res.data.errors[0].message))
        } else {
          setFieldValue('addressLine1', res.data.address_line1 || '')
          setFieldValue('addressLine2', res.data.address_line2 || '')
          setFieldValue('city', res.data.city || '')
          setFieldValue('region', res.data.region || '')
          setFieldValue('zipCode', res.data.zip_code || '')
          setFieldValue('country', res.data.country || '')
          setCountry(res.data.country || '')
          // dispatch(fetchFormattedAddressSuccess())
        }
      })
      .catch(error => {
        // dispatch(fetchFormattedAddressFailure(error))
      })
  }
}

export default fetchFormattedAddress
