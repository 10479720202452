import React from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import {HideModalIcon} from 'modals'
import PropTypes from 'prop-types'

const ModalBodyWrapper = styled.div`
  text-align: center;

  @media ${props => props.theme.devices.mobile} {
    iframe {
      height: 250px;
      width: 100%;
    }
  }

  @media ${props => props.theme.devices.tablet} {
    iframe {
      height: 300px;
      width: 100%;
    }
  }

  @media ${props => props.theme.devices.laptop} {
    iframe {
      height: 350px;
      width: 100%;
    }
  }

  @media ${props => props.theme.devices.desktop} {
    iframe {
      height: 550px;
      width: 100%;
    }
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
`

function VideoModal(props) {
  return (
    <Modal
      centered
      show={props.show}
      onHide={() => props.setShow(false)}
      size='xl'
    >
      <Modal.Header>
        <HeaderWrapper>
          {props.heading}
          <HideModalIcon setShow={props.setShow} />
        </HeaderWrapper>
      </Modal.Header>
      <ModalBodyWrapper heading={props.heading}>
        <Modal.Body>{props.children}</Modal.Body>
      </ModalBodyWrapper>
    </Modal>
  )
}

export default VideoModal

VideoModal.propTypes = {
  size: PropTypes.string,
  heading: PropTypes.string,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
}
