import React, { useState } from 'react'
import Button from './Button'
import { PlayCircleFill } from 'react-bootstrap-icons'
import styled from 'styled-components'
import { useDevice } from 'functions'
import {VideoModal} from 'modals'

const Wrapper = styled.div``

function VideoWalkthroughButton(props) {
  const [show, setShow] = useState(false)
  const device = useDevice();
  const buttonText = device === 'desktop' ? 'Video Walkthrough' : '';

  return (
    <Wrapper>
      <Button
        onClick={() => setShow(true)}
        content={<span><PlayCircleFill /> {buttonText}</span>}
        size={props => props.theme.space.s}
        backgroundColor={props => props.theme.colors.black}
      />

      <VideoModal
        show={show}
        setShow={setShow}
        size="xl"
        heading={props.heading}
      >
        <iframe
          src={props.url}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </VideoModal>
    </Wrapper>
  )
}

export default VideoWalkthroughButton
