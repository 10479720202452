import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'

function MobileSidebarLink(props) {
  const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    padding-left: ${props => props.theme.space.l};
    padding-top: ${props => props.theme.space.l};
    height: 100%;
  `

  const LinkText = styled.div`
    color: ${props => props.theme.colors.white};
    font-weight: ${props => (props.active ? 800 : 'normal')};
  `
  const ActiveBar = styled.div`
    width: 5px;
    height: 50px;
    background-color: ${props => props.theme.colors.white};
  `

  let link

  if (props.external) {
    link = (
      <LinkWrapper onClick={props.onClick}>
        <Col xs="12">
          <a href={props.to} target='_blank' rel="noopener noreferrer">
            <LinkText>{props.text}</LinkText>
          </a>
        </Col>
      </LinkWrapper>
    )
  } else if (props.to === window.location.pathname) {
    link = (
      <LinkWrapper active onClick={props.onClick}>
        <Col xs="1">
          <ActiveBar active />
        </Col>
        <Col xs="11">
          <Link to={props.to}>
            <LinkText active>{props.text}</LinkText>
          </Link>
        </Col>
      </LinkWrapper>
    )
  } else {
    link = (
      <LinkWrapper onClick={props.onClick}>
        <Col xs="12">
          <Link to={props.to}>
            <LinkText>{props.text}</LinkText>
          </Link>
        </Col>
      </LinkWrapper>
    )
  }

  return link
}

export default MobileSidebarLink
