import React from 'react'
import styled from 'styled-components'
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import { Button } from 'buttons'
import {Link} from 'react-router-dom'

const ButtonWrapper = styled.div`
  padding-right: 15px;

  #button-content {
    display: flex;
    justify-content: center;
    align-items: center;

    #button-text {
      margin-left: 15px;
    }
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
`

function ViewResourceButton(props) {
  function backgroundColor() {
    if (props.backgroundColor) {
      return props.backgroundColor
    } else {
      return props => props.theme.colors.primary
    }
  }

  function renderLink(){
    if (props.external){
      return(
        <a href={props.link} target='_blank' rel='noopener noreferrer'>
          <Button
            onClick={props.onClick}
            content={<span><BoxArrowUpRight /> {props.text}</span>}
            backgroundColor={props => props.theme.colors.offWhite}
            color={props => props.theme.colors.primary}
            size={props => props.theme.space.s}
          />
        </a>
      )
    } else {
      return(
        <Link to={props.link}>
          <Button
            onClick={props.onClick}
            content={<span><BoxArrowUpRight /> {props.text}</span>}
            backgroundColor={props => props.theme.colors.offWhite}
            color={props => props.theme.colors.primary}
            size={props => props.theme.space.s}
          />
        </Link>
      )
    }
  }

  return (
    <ButtonWrapper id="view-resource-button">
      {renderLink()}
    </ButtonWrapper>
  )
}

export default ViewResourceButton
