import React, {useState} from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {SubmitButton} from 'buttons'
import {TextInput} from 'forms'
import {login} from 'redux/actions/authentication/login'
import {connect} from 'react-redux'
import LoginFormSubmission from 'spas/Login/LoginFormSubmission.js'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {useSnackbar} from 'notistack'

const ForgottenPasswordLink = styled.div`
  padding-top: ${props => props.theme.space.l};  
  padding-bottom: ${props => props.theme.space.l};
  text-align: center;

  a {
    color: ${props => props.theme.colors.primary};
  }
`

const FormSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
})

function LoginForm(props) {
  const {enqueueSnackbar} = useSnackbar()
  const [response, setResponse] = useState({})

  function handleResponse(response) {
    if (response.status === 'success') {
      setResponse(response)
      window.setTimeout(function () {
        window.location.href = '/'
      }, 1000)
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
      window.setTimeout(function () {
        window.location.reload()
      }, 1000)
    }
  }

  return (
    <div>
      <Formik
        initialValues={{email: '', password: ''}}
        validationSchema={FormSchema}
        onSubmit={(values, {setSubmitting}) => {
          props.login(values, handleResponse)
        }}
      >
        {({isSubmitting}) => (
          <Form>
            <TextInput label="Email" name="email" type="email" id='email' />

            <TextInput label="Password" name="password" type="password" id='password' />

            <SubmitButton content="Log in" isSubmitting={isSubmitting} />

            <ForgottenPasswordLink>
              <Link to="/reset-password-request">
                Ooops! I forgot my password!
              </Link>
            </ForgottenPasswordLink>
          </Form>
        )}
      </Formik>

      <LoginFormSubmission response={response} />
    </div>
  )
}

const mapDispatchToProps = {
  login: login,
}

export default connect(null, mapDispatchToProps)(LoginForm)
