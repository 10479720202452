function NetworkRequestOutcomes(props) {
  switch (props.response.status) {
    case 'requested': {
      return props.requested
    }

    case 'success': {
      return props.success
    }

    case 'error': {
      return props.error
    }

    default:
      return null
  }
}

export default NetworkRequestOutcomes
