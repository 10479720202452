import React from 'react'
import styled from 'styled-components'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${props => (props.color ? props.color : 'white')};
  cursor: pointer;
`

function CardMenuIcon(props) {
  return (
    <Wrapper id="card-menu-icon" color={props.color} onClick={props.onClick}>
      <MoreVertIcon />
    </Wrapper>
  )
}

export default CardMenuIcon
