import React from 'react'
import styled from 'styled-components'
import Button from './Button'

const Wrapper = styled.div`
  #button {
    #button-content {
      padding: 15px;
    }
  }
`

function ModalButton(props) {
  return (
    <Wrapper onClick={props.handleClick}>
      <Button
        content={props.text}
        borderRadius="8px"
        fontSize="18px"
        backgroundColor={props.backgroundColor}
        color={props.color}
      />
    </Wrapper>
  )
}

export default ModalButton
