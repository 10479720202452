import {LOGIN_REQUEST} from 'redux/actionTypes.js'
import {LOGIN_SUCCESS} from 'redux/actionTypes.js'
import {LOGIN_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function login(values, handleResponse) {
  return function (dispatch) {
    dispatch(loginRequest(values.email))

    let variables = {
      email: values.email,
      password: values.password,
    }

    let query = `
      mutation($email: String!, $password: String!) {
        signIn(
          email: $email
          password: $password
        ) {
          message
          jwtToken
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {},
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(loginFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          Cookies.set('jwtToken', res.data.data.signIn.jwtToken, {expires: 1})
          dispatch(loginSuccess())
          handleResponse({
            status: 'success',
          })
        }
      })
      .catch(error => {
        dispatch(loginFailure())
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const loginRequest = () => ({
  type: LOGIN_REQUEST,
})

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
})

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: {
    error: error,
  },
})
