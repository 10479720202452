import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { PageHeading } from 'headings'

const Wrapper = styled.div`
  padding-bottom: ${props => props.theme.space.m};
  
  hr {
    margin-top: ${props => props.theme.space.m};
  }
`

function Actions(props) {
  return (
    <Wrapper id='actions' paddingTop={props.paddingTop}>
      <Row>
        <Col xs='2' md='3'>
          {props.action}
        </Col>

        <Col xs='8' md='6'>
          <PageHeading text={props.heading} />
        </Col>

        <Col xs='2' md='3'>
          {props.menu}
        </Col>
      </Row>
    </Wrapper>
  )
}

export default Actions
