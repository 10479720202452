import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'
import {useField} from 'formik'
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 100%;
  height: 48px;
  border: 1px solid #999999;
  border-radius: 8px;
  background-color: white;
  font-size: 16px;
  font-family: 'Nunito Sans', sans-serif;
  text-align: left;

  .MuiSelect-select:focus {
    background-color: white;
  }
`

const StyledMenuItem = styled(MenuItem)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  min-height: 32px;
`

function SelectWrapper(props) {
  const [field, meta] = useField(props.name)

  function handleChange(event) {
    if (props.submitOnChange) {
      props.submitOnChange(event, props.handleChange, props.submitForm)
    } else {
      props.handleChange(event)
    }
  }

  let options = props.options.map(function (option) {
    return <StyledMenuItem value={option.value}>{option.name}</StyledMenuItem>
  })

  let select

  if (props.handleChange) {
    select = (
      <StyledSelect
        id="select"
        input={<InputBase />}
        {...field}
        name={props.name}
        type={props.type}
        onChange={handleChange}
        value={props.value}
      >
        {options}
      </StyledSelect>
    )
  } else {
    select = (
      <StyledSelect
        id="select"
        input={<InputBase />}
        {...field}
        name={props.name}
        type={props.type}
        value={props.value}
      >
        {options}
      </StyledSelect>
    )
  }

  return select
}

export default SelectWrapper
