import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`

function NavbarItem(props) {
  return <Wrapper id="navbar-item">{props.children}</Wrapper>
}

export default NavbarItem
