import { useState, useEffect } from 'react'

function useDevice() {
  const size = {
    mobile: 576,
    tablet: 768,
    laptop: 992,
    desktop: 1200,
  }

  const isClient = typeof window === 'object'

  function getDevice() {
    if (window.innerWidth < size.mobile) {
      return 'mobile'
    } else if (window.innerWidth <= size.tablet) {
      return 'tablet'
    } else if (window.innerWidth <= size.laptop) {
      return 'laptop'
    } else {
      return 'desktop'
    }
  }

  const [device, setDevice] = useState(getDevice)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setDevice(getDevice())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return device
}

export default useDevice
