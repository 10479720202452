import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: ${props => props.theme.space.l};

  #card {
    padding-left: ${props => props.theme.space.l} !important;
    padding-right: ${props => props.theme.space.l} !important;
  }

  @media ${props => props.theme.devices.desktop} {
    padding-left: 20%;
    padding-right: 20%;
  }
`

function FormWrapper(props) {
  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  )
}

export default FormWrapper
