const regex = /@\[.+?\]\(.+?\)/gm
const idRegex = /\(.+?\)/g

const useTextWithMentions = (comment = '') => {
  const replacer = match => {
    const mentionWithoutId = removeId(match)
    const formattedMention = formatMention(mentionWithoutId)
    return `<a href="#">${formattedMention}</a>`
  }

  const removeId = mention => {
    return mention.replace(idRegex, '')
  }

  const formatMention = mention => {
    return mention.replace('[', '').replace(']', '')
  }

  return comment.replace(regex, replacer)
}

export default useTextWithMentions
