import React from 'react'
import Container from 'react-bootstrap/Container'
import {PublicTopNavbar} from 'navs'
import {Card} from 'cards'
import LoginForm from 'spas/Login/LoginForm.js'
import {useAuthenticated} from 'functions'
import {Page} from 'pages'
import {Heading} from 'forms'
import {FormWrapper} from 'forms'

function Login(props) {
  const authenticated = useAuthenticated()

  if (authenticated) {
    return (window.location.href = '/')
  } else {
    return (
      <Container fluid>
        <PublicTopNavbar />
        <Page>
          <FormWrapper>
            <Card
              backgroundColor={props => props.theme.colors.white}
            >
              <Heading text='Log In' />
              <LoginForm />
            </Card>
          </FormWrapper>
        </Page>
      </Container>
    )
  }
}

export default Login
