import React from 'react'
import styled from 'styled-components'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { LabelWrapper } from 'forms'

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: ${props => props.theme.space.l};
`

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;

  input {
    cursor: pointer;
  }
`

const Input = styled.div`
  display: flex;
  width: 100%;
  padding-left: 15px;
  align-items: center;
  width: 100%;
  height: 48px;
  background: ${props => props.theme.colors.white};
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
  border-radius: 8px;

  display: ${props => (props.ellipsis ? 'inline-block' : 'flex')};
  white-space: ${props => (props.ellipsis ? 'nowrap' : 'unset')};
  overflow: ${props => (props.ellipsis ? 'hidden' : 'unset')};
  text-overflow: ${props => (props.ellipsis ? 'ellipsis' : 'unset')};
  padding-right: ${props => (props.ellipsis ? '26px' : '0')};
  line-height: ${props => (props.ellipsis ? '48px' : 'unset')};
`

const InputIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 10px;
`

function OpenModalInput(props) {
  function handleClick() {
    props.showModal(true)
  }

  function label() {
    if (props.label) {
      return (
        <LabelWrapper
          label={props.label}
          name={props.name}
          toolTipContent={props.toolTipContent}
          width={props.labelWidth}
        />
      )
    }
  }

  return (
    <Wrapper id='open-modal-input'>
      {label()}
      <InputWrapper onClick={handleClick}>
        <Input id="input" ellipsis>
          {props.text}
        </Input>
        <InputIcon>
          <ArrowForwardIosIcon style={props.iconStyle} />
        </InputIcon>
      </InputWrapper>
      {props.children}
    </Wrapper>
  )
}

export default OpenModalInput
