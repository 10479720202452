import { useSelector } from 'react-redux'

function usePrimaryColor() {
  const currentAccountUser = useSelector(state => state.profile.currentAccountUser || {})

  let tenant = currentAccountUser.tenant || {}
  let propertyInvestor = currentAccountUser.propertyInvestor || {}
  let mortgageBroker = currentAccountUser.mortgageBroker || {}

  if (propertyInvestor.id) return '#4DC37C'
  if (mortgageBroker.id) return '#2A3E87'
  if (tenant.id) return '#54c3c9'
  return '#4DC37C'
}

export default usePrimaryColor