import React from 'react'
import styled from 'styled-components'
import {useField} from 'formik'

const Text = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.space.m};
  padding-bottom: ${props => props.theme.space.s};
  text-align: left;

  color: ${props => (props.color ? props.color : props.theme.colors.black)};
`

const Wrapper = styled.div`
  display: flex;

  #error-message {
    font-size: 12px;
    padding-left: ${props => props.theme.space.m};
    color: ${props => props.theme.colors.red};
  }
`

function Label(props) {
  const [field, meta] = useField(props.name)

  function error() {
    if (props.array) {
      return (
        <div id="error-message" className="error">
          {meta.error}
        </div>
      )
    } else if (meta.touched && meta.error) {
      return (
        <div id="error-message" className="error">
          {meta.error}
        </div>
      )
    }
  }

  return (
    <Wrapper id="label">
      <Text id="label-text" color={props.color}>
        {props.text}
      </Text>
      {error()}
    </Wrapper>
  )
}

export default Label
