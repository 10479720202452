function restoreAll({
  enqueueSnackbar: enqueueSnackbar,
  records: records,
  showModal: showModal,
  restore: restore,
  restoreUrl: restoreUrl,
}) {

  enqueueSnackbar('Records are being restored. Please stay on this page.', { variant: 'info' })

  records.map(function (record) {
    return (
      restore({
        id: record.id,
        showModal: showModal,
        enqueueSnackbar: enqueueSnackbar,
      })
    )
  })

  window.setTimeout(() => {
    window.location.href = restoreUrl
  }, 2000)
}

export default restoreAll