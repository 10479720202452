import React from 'react'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import { Link } from 'react-router-dom'
import { Brand } from 'navs'
import { useScreenWidth } from 'functions'
import PropTypes from 'prop-types'

function MobileSidebarLinks(props) {
  const screenWidth = useScreenWidth()

  const ListWrapper = styled.div`
    background-color: ${props => props.theme.colors.green};
    width: ${props => props.screenWidth * 0.75}px;
    height: 100%;

    #brand {
      padding-left: ${props => props.theme.space.l}
    }
  `

  return (
    <ListWrapper
      className="list"
      role="presentation"
      onClick={props.toggleDrawer(props.anchor, false)}
      onKeyDown={props.toggleDrawer(props.anchor, false)}
      screenWidth={screenWidth}
    >
      <List>
        <Link to="/">
          <Brand />
        </Link>

        {props.children}
      </List>
    </ListWrapper>
  )
}

export default MobileSidebarLinks

MobileSidebarLinks.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  anchor: PropTypes.string.isRequired,
  screenWidth: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
}
