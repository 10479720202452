import React from 'react'
import { ConfirmationModal } from 'modals'

function CommentConfirmationModal(props) {
  return (
    <ConfirmationModal
      show={props.show}
      setShow={props.setShow}
      handleConfirm={props.handleConfirm}
      heading="Are you sure you want to add this comment, without tagging anyone?"
      description="People will only be notified if you tag them. You can tag people using the @ symbol, just like on social media. If the person you're trying to tag, is not appearing in the list, you should invite them to this workflow."
    />
  )
}

export default CommentConfirmationModal
