import React from 'react'
import Container from 'react-bootstrap/Container'
import { Page } from 'v2'
import {TopNavbar} from 'navs'
import { PageCard } from 'pages'

function IndexPage(props) {
  return (
    <Container fluid>
      <TopNavbar />
      <Page>
        <PageCard>
          {props.children}
        </PageCard>
      </Page>
    </Container>
  )
}

export default IndexPage
