import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import {connect} from 'react-redux'
import {accountSearch} from 'mutations'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .MuiAutocomplete-root {
    width: 100%;
  }

  .MuiInputBase-root {
    padding-left: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background: ${props => props.theme.colors.white};
    box-sizing: border-box;
    border-radius: 8px;
  }
`

function SearchWrapper(props) {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && props.accountSearchResults.length === 0

  function handleChange(event) {
    props.accountSearch(event.target.value, handleResponse)
  }

  function handleResponse(response) {
    let results = response.accountSearchResults.map(function (account) {
      return {
        name: account.name,
        value: account.id,
      }
    })
    setOptions(results)
  }

  function handleOptionSelected(option) {
    props.setFieldValue('accountId', option.value)
  }

  return (
    <Wrapper>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name}
        options={options}
        loading={loading}
        onChange={(event, value, reason) => handleOptionSelected(value)}
        renderInput={params => (
          <TextField
            {...params}
            onChange={event => handleChange(event)}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Wrapper>
  )
}

const mapStateToProps = state => {
  return {
    accountSearchResults: state.stacked.accountSearchResults,
  }
}

const mapDispatchToProps = {
  accountSearch: accountSearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchWrapper)
