import React from 'react'
import {VideoModal} from 'modals'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { QuestionCircleFill } from 'react-bootstrap-icons'

const Wrapper = styled.div`
  padding-bottom: ${props => props.theme.space.l};

  ol {
    justify-content: center;
    background-color: ${props => props.theme.colors.white};
  }

  .breadcrumb {
    padding-top: ${props => props.theme.space.m};
    padding-bottom: ${props => props.theme.space.m};
    padding-left: 0px;
    padding-right: 0px;

    margin: 0px;
  }

  .active {
    font-weight: 800;
  }
`

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`

const SupportWrapper = styled.div`
  text-align: center;

  svg {
    color: ${props => props.theme.colors.black};
    cursor: pointer;
  }
`

function Breadcrumbs(props) {
  function renderSupportIcon() {
    if (props.supportVideoLink) {
      return(
        <SupportWrapper>
          <QuestionCircleFill size={25} onClick={() => props.setShowSupportVideo(true)} />

          <VideoModal
            show={props.showSupportVideo}
            setShow={props.setShowSupportVideo}
            size="xl"
            heading={props.supportVideoHeading}
          >
            <iframe
              src={props.supportVideoLink}
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </VideoModal>
        </SupportWrapper>
      )
    }
  }

  return (
    <Wrapper>
      <Row>
        <Col xs='1'></Col>
        <Col xs='10'>
          {props.children}
        </Col>
        <Col xs='1'>
          <OptionsWrapper>
            {/* {renderSupportIcon()} */}
          </OptionsWrapper>
        </Col>
      </Row>
      <hr />
    </Wrapper>
  )
}

export default Breadcrumbs
