export const FETCH_WORKFLOW_REQUEST = 'FETCH_WORKFLOW_REQUEST'
export const FETCH_WORKFLOW_SUCCESS = 'FETCH_WORKFLOW_SUCCESS'
export const FETCH_WORKFLOW_FAILURE = 'FETCH_WORKFLOW_FAILURE'

export const CREATE_WORKFLOW_REQUEST = 'CREATE_WORKFLOW_REQUEST'
export const CREATE_WORKFLOW_SUCCESS = 'CREATE_WORKFLOW_SUCCESS'
export const CREATE_WORKFLOW_FAILURE = 'CREATE_WORKFLOW_FAILURE'

export const UPDATE_WORKFLOW_REQUEST = 'UPDATE_WORKFLOW_REQUEST'
export const UPDATE_WORKFLOW_SUCCESS = 'UPDATE_WORKFLOW_SUCCESS'
export const UPDATE_WORKFLOW_FAILURE = 'UPDATE_WORKFLOW_FAILURE'

export const ARCHIVE_WORKFLOW_REQUEST = 'ARCHIVE_WORKFLOW_REQUEST'
export const ARCHIVE_WORKFLOW_SUCCESS = 'ARCHIVE_WORKFLOW_SUCCESS'
export const ARCHIVE_WORKFLOW_FAILURE = 'ARCHIVE_WORKFLOW_FAILURE'

export const UNARCHIVE_WORKFLOW_REQUEST = 'UNARCHIVE_WORKFLOW_REQUEST'
export const UNARCHIVE_WORKFLOW_SUCCESS = 'UNARCHIVE_WORKFLOW_SUCCESS'
export const UNARCHIVE_WORKFLOW_FAILURE = 'UNARCHIVE_WORKFLOW_FAILURE'

export const DELETE_WORKFLOW_REQUEST = 'DELETE_WORKFLOW_REQUEST'
export const DELETE_WORKFLOW_SUCCESS = 'DELETE_WORKFLOW_SUCCESS'
export const DELETE_WORKFLOW_FAILURE = 'DELETE_WORKFLOW_FAILURE'

export const ADD_WORKFLOW_INVITATION_REQUEST = 'ADD_WORKFLOW_INVITATION_REQUEST'
export const ADD_WORKFLOW_INVITATION_SUCCESS = 'ADD_WORKFLOW_INVITATION_SUCCESS'
export const ADD_WORKFLOW_INVITATION_FAILURE = 'ADD_WORKFLOW_INVITATION_FAILURE'

export const DELETE_WORKFLOW_INVITATION_REQUEST = 'DELETE_WORKFLOW_INVITATION_REQUEST'
export const DELETE_WORKFLOW_INVITATION_SUCCESS = 'DELETE_WORKFLOW_INVITATION_SUCCESS'
export const DELETE_WORKFLOW_INVITATION_FAILURE = 'DELETE_WORKFLOW_INVITATION_FAILURE'

export const ADD_WORKFLOW_ASSIGNEE_REQUEST = 'ADD_WORKFLOW_ASSIGNEE_REQUEST'
export const ADD_WORKFLOW_ASSIGNEE_SUCCESS = 'ADD_WORKFLOW_ASSIGNEE_SUCCESS'
export const ADD_WORKFLOW_ASSIGNEE_FAILURE = 'ADD_WORKFLOW_ASSIGNEE_FAILURE'

export const DELETE_WORKFLOW_ASSIGNEE_REQUEST = 'DELETE_WORKFLOW_ASSIGNEE_REQUEST'
export const DELETE_WORKFLOW_ASSIGNEE_SUCCESS = 'DELETE_WORKFLOW_ASSIGNEE_SUCCESS'
export const DELETE_WORKFLOW_ASSIGNEE_FAILURE = 'DELETE_WORKFLOW_ASSIGNEE_FAILURE'

export const ADD_REMINDER_TO_WORKFLOW_REQUEST = 'ADD_REMINDER_TO_WORKFLOW_REQUEST'
export const ADD_REMINDER_TO_WORKFLOW_SUCCESS = 'ADD_REMINDER_TO_WORKFLOW_SUCCESS'
export const ADD_REMINDER_TO_WORKFLOW_FAILURE = 'ADD_REMINDER_TO_WORKFLOW_FAILURE'

export const UPDATE_REMINDER_REQUEST = 'UPDATE_REMINDER_REQUEST'
export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS'
export const UPDATE_REMINDER_FAILURE = 'UPDATE_REMINDER_FAILURE'

export const DELETE_REMINDER_FROM_WORKFLOW_REQUEST = 'DELETE_REMINDER_FROM_WORKFLOW_REQUEST'
export const DELETE_REMINDER_FROM_WORKFLOW_SUCCESS = 'DELETE_REMINDER_FROM_WORKFLOW_SUCCESS'
export const DELETE_REMINDER_FROM_WORKFLOW_FAILURE = 'DELETE_REMINDER_FROM_WORKFLOW_FAILURE'

export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST'
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS'
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE'

export const UPDATE_COMMENT_REQUEST = 'UPDATE_COMMENT_REQUEST'
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS'
export const UPDATE_COMMENT_FAILURE = 'UPDATE_COMMENT_FAILURE'

export const DELETE_CONTRIBUTION_REQUEST = 'DELETE_CONTRIBUTION_REQUEST'
export const DELETE_CONTRIBUTION_SUCCESS = 'DELETE_CONTRIBUTION_SUCCESS'
export const DELETE_CONTRIBUTION_FAILURE = 'DELETE_CONTRIBUTION_FAILURE'

export const ADD_REACTION_REQUEST = 'ADD_REACTION_REQUEST'
export const ADD_REACTION_SUCCESS = 'ADD_REACTION_SUCCESS'
export const ADD_REACTION_FAILURE = 'ADD_REACTION_FAILURE'

export const DELETE_REACTION_REQUEST = 'DELETE_REACTION_REQUEST'
export const DELETE_REACTION_SUCCESS = 'DELETE_REACTION_SUCCESS'
export const DELETE_REACTION_FAILURE = 'DELETE_REACTION_FAILURE'

export const ADD_CONVERSATION_DOCUMENT_REQUEST = 'ADD_CONVERSATION_DOCUMENT_REQUEST'
export const ADD_CONVERSATION_DOCUMENT_SUCCESS = 'ADD_CONVERSATION_DOCUMENT_SUCCESS'
export const ADD_CONVERSATION_DOCUMENT_FAILURE = 'ADD_CONVERSATION_DOCUMENT_FAILURE'

export const UPLOAD_CONVERSATION_DOCUMENT_FILE_REQUEST = 'UPLOAD_CONVERSATION_DOCUMENT_FILE_REQUEST'
export const UPLOAD_CONVERSATION_DOCUMENT_FILE_SUCCESS = 'UPLOAD_CONVERSATION_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_CONVERSATION_DOCUMENT_FILE_FAILURE = 'UPLOAD_CONVERSATION_DOCUMENT_FILE_FAILURE'

export const UPDATE_CONVERSATION_DOCUMENT_REQUEST = 'UPDATE_CONVERSATION_DOCUMENT_REQUEST'
export const UPDATE_CONVERSATION_DOCUMENT_SUCCESS = 'UPDATE_CONVERSATION_DOCUMENT_SUCCESS'
export const UPDATE_CONVERSATION_DOCUMENT_FAILURE = 'UPDATE_CONVERSATION_DOCUMENT_FAILURE'

export const DELETE_CONVERSATION_DOCUMENT_REQUEST = 'DELETE_CONVERSATION_DOCUMENT_REQUEST'
export const DELETE_CONVERSATION_DOCUMENT_SUCCESS = 'DELETE_CONVERSATION_DOCUMENT_SUCCESS'
export const DELETE_CONVERSATION_DOCUMENT_FAILURE = 'DELETE_CONVERSATION_DOCUMENT_FAILURE'

export const UPDATE_PIPELINE_STATUS_REQUEST = 'UPDATE_PIPELINE_STATUS_REQUEST'
export const UPDATE_PIPELINE_STATUS_SUCCESS = 'UPDATE_PIPELINE_STATUS_SUCCESS'
export const UPDATE_PIPELINE_STATUS_FAILURE = 'UPDATE_PIPELINE_STATUS_FAILURE'

export const FETCH_CONVERSATION_CONTRIBUTIONS_REQUEST = 'FETCH_CONVERSATION_CONTRIBUTIONS_REQUEST'
export const FETCH_CONVERSATION_CONTRIBUTIONS_SUCCESS = 'FETCH_CONVERSATION_CONTRIBUTIONS_SUCCESS'
export const FETCH_CONVERSATION_CONTRIBUTIONS_FAILURE = 'FETCH_CONVERSATION_CONTRIBUTIONS_FAILURE'

export const ADD_REMINDER_TO_CALENDAR_EVENT_REQUEST = 'ADD_REMINDER_TO_CALENDAR_EVENT_REQUEST'
export const ADD_REMINDER_TO_CALENDAR_EVENT_SUCCESS = 'ADD_REMINDER_TO_CALENDAR_EVENT_SUCCESS'
export const ADD_REMINDER_TO_CALENDAR_EVENT_FAILURE = 'ADD_REMINDER_TO_CALENDAR_EVENT_FAILURE'

export const SET_LINKED_COMMENT = 'SET_LINKED_COMMENT'
