import { useCurrentUser } from 'functions'

function useAuthenticated() {
  const currentUser = useCurrentUser()

  if (
    typeof currentUser.id !== 'undefined' &&
    currentUser.signUpStatus !== 'invited_to_presentation' &&
    currentUser.signUpStatus !== 'pre_registered'
  ) {
    return true
  } else {
    return false
  }
}

export default useAuthenticated