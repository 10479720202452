import React from 'react'
import styled from 'styled-components'
import Badge from 'react-bootstrap/Badge'

const Wrapper = styled.div`
  #badge-wrapper {
    background-color: ${props => props.theme.colors.green} !important;
    color: white;
    font-size: 16px;
  }
`

function DealTypeBadge(props) {
  let type = props.type

  function formattedTypeName() {
    if (type === 'single_let') {
      return 'Single let'
    } else if (type === 'hmo') {
      return 'House in multiple occupation'
    } else if (type === 'flip') {
      return 'Flip'
    } else if (type === 'commercial_to_residential') {
      return 'Multi dwelling'
    } else if (type === 'rent_to_rent') {
      return 'Rent to rent'
    } else if (type === 'rent_to_serviced_accommodation') {
      return 'Rent to serviced accommodation'
    } else if (type === 'serviced_accommodation') {
      return 'Serviced accommodation'
    }
  }

  return (
    <Wrapper id='deal-type-badge'>
      <Badge pill id='badge-wrapper'>
        {formattedTypeName()}
      </Badge>
    </Wrapper>
  )
}

export default DealTypeBadge
