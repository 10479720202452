import React from 'react'
import { Breadcrumbs } from 'pages'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom'

function Navigation() {

  return (
    <Breadcrumbs>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
    </Breadcrumbs>
  )
}

export default Navigation