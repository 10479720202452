import React from 'react'
import styled from 'styled-components'
import VisualiseDataButton from './VisualiseDataButton';
import VisualiseTextButton from './VisualiseTextButton';

const Wrapper = styled.div`
  display: flex;
  width: 100;
  #button {
    margin-left: 5px;
    margin-right: 5px;
  }
`

function DataVisualisationButtons(props) {
  let visualise = props.visualise

  return (
    <Wrapper>
      <VisualiseTextButton
        visualise={visualise}
        setVisualise={props.setVisualise}
      />
      <VisualiseDataButton
        visualise={visualise}
        setVisualise={props.setVisualise}
      />
    </Wrapper>
  )
}

export default DataVisualisationButtons