import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .text-input {
    padding-left: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background: ${props => props.theme.colors.white};
    border: 1px solid #b7b7b7;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: ${props =>
      props.disabled ? props.theme.colors.offWhite : ''};
  }

  .text-input:focus {
    outline: none;
  }

  position: relative;
`

const ControlledInputWrapper = props => {
  let step = props.step ? props.step : 'any'

  return (
    <Wrapper disabled={props.disabled}>
      <input
        id={props.id}
        className="text-input"
        type={props.type}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        onClick={props.onClick}
        max={props.max}
        step={step}
        onKeyPress={props.onKeyPress}
        onChange={props.handleChange}
        value={props.value}
      />
    </Wrapper>
  )
}

export default ControlledInputWrapper
