import React from 'react'
import styled from 'styled-components'
import { LabelWrapper } from 'forms'
import Form from 'react-bootstrap/Form'

const FieldWrapper = styled.div`
  .file-label > .custom-file-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Rules = styled.div`
  padding-top: ${props => props.theme.space.l};
  padding-bottom: ${props => props.theme.space.l};
`

const Rule = styled.div`
  display: flex;
  justify-content: flex-start;
`

function FileField(props) {
  function handleChange(event) {
    props.setFieldValue(props.name, event.currentTarget.files[0])
  }

  return (
    <FieldWrapper id='file-field'>
      <LabelWrapper
        label={props.label}
        name={props.name}
        toolTipContent={props.toolTipContent}
        required={props.required}
      />
      <Form.Control type="file" onChange={handleChange} />
      <Rules>
        <Rule>{props.formats}</Rule>
        <Rule>{props.sizes}</Rule>
      </Rules>
    </FieldWrapper>
  )
}

export default FileField
