import React from 'react'
import Button from './Button';
import { List } from 'react-bootstrap-icons';

function VisualiseTextButton(props) {
  let visualise = props.visualise

  if (visualise === false) {
    return <Button content={<List />} size={props => props.theme.space.s} />
  } else if (visualise === true) {
    return (
      <Button
        onClick={() => props.setVisualise(false)}
        content={<List />}
        backgroundColor={props => props.theme.colors.lightGrey}
        size={props => props.theme.space.s}
      />
    )
  } else {
    return null
  }
}

export default VisualiseTextButton
