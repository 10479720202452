import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  #button {
    border-radius: 8px;
    width: 100%;

    #button-content {
      padding: 10px;
      font-weight: normal;
    }
  }
`

function DismissModalButton(props) {
  return (
    <Wrapper id="dismiss-modal-button">
      <Button
        onClick={() => props.setShow(false)}
        text="Dismiss"
        fontSize="16px"
        backgroundColor={props => props.theme.colors.offWhite}
        color={props => props.theme.colors.black}
      />
    </Wrapper>
  )
}

export default DismissModalButton

DismissModalButton.propTypes = {
  setShow: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
}
