import React from 'react'
import styled from 'styled-components'
import { CheckCircleFill } from 'react-bootstrap-icons';

const Wrapper = styled.button`
  background-color: inherit;
  border: none;

  svg {
    color: ${props => props.theme.colors.primary};
  }
`

function Checkmark(props) {
  let size = props.size || 25

  return (
    <Wrapper>
      <CheckCircleFill 
        size={size} 
        onClick={props.onClick}
      />
    </Wrapper>
  )
}

export default Checkmark
