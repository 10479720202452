import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { InputValidationIcon} from 'forms'
import {MentionsInput, Mention} from 'react-mentions'
import { useSnackbar } from 'notistack'


const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .mention-input {
    padding-left: ${props => props.theme.space.m};
    display: flex;
    width: 100%;
    min-height: 48px;
    align-items: center;
    background: ${props => props.theme.colors.white};

    &__control {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 400;
    }

    &__highlighter {
      padding: 12px 0 12px 15px;
      border: none;
      min-height: 48px;
      overflow: hidden;
    }

    &__input {
      padding-top: ${props => props.theme.space.m};
      padding-bottom: ${props => props.theme.space.m};
      // padding-left: ${props => props.theme.space.l};
      border: none;
      overflow: auto !important;
      min-height: 48px;

      &:focus {
        outline: none;
      }
    }
  }
`

const Suggestion = styled.div``

const neverMatchingRegex = /($a)/

function MentionWrapper(props) {
  const { enqueueSnackbar } = useSnackbar()
  const [emojis, setEmojis] = useState([])

  useEffect(() => {
    fetch(
      'https://gist.githubusercontent.com/oliveratgithub/0bf11a9aff0d6da7b46f1490f86a71eb/raw/d8e4b78cfe66862cf3809443c1dba017f37b61db/emojis.json',
    )
      .then(response => {
        return response.json()
      })
      .then(jsonData => {
        setEmojis(jsonData.emojis)
      })
  }, [])

  const queryEmojis = (query, callback) => {
    if (query.length < 2) return null

    const matches = emojis.filter(emoji => {
      return emoji.shortname.indexOf(query) > -1
    })
    callback(
      matches.map(({emoji, shortname}) => ({
        id: emoji,
        display: `${emoji} ${shortname}`,
      })),
    )
  }

  const updateRecipientIds = mentions => {
    const recipientIds = mentions.map(mention => mention.id)
    props.setFieldValue('recipientIds', recipientIds)
  }

  const handleChange = (e, newValue, newPlainText, mentions) => {
    if (e.target.value.includes('@') && props.options.length === 0) {
      enqueueSnackbar('You have not invited anyone to this workflow', { variant: 'error' })
    } else {
      props.setFieldValue('text', e.target.value)
      updateRecipientIds(mentions)
    }
  }

  const renderSuggestion = (suggestion, search, highlightedDisplay) => (
    <Suggestion>{highlightedDisplay}</Suggestion>
  )

  return (
    <Wrapper disabled={props.disabled}>
      <MentionsInput
        placeholder={props.placeholder}
        className="mention-input"
        onChange={handleChange}
        value={props.text}
        inputRef={props.mentionRef}
      >
        <Mention
          trigger="@"
          data={props.options}
          renderSuggestion={renderSuggestion}
          displayTransform={(id, display) => `@${display}`}
          className="mention-text"
          markup="@[__display__](__id__)"
        />
        <Mention
          trigger=":"
          markup="__id__"
          regex={neverMatchingRegex}
          data={queryEmojis}
        />
      </MentionsInput>
      {/* <InputValidationIcon touched={meta.touched} error={meta.error} /> */}
    </Wrapper>
  )
}

export default MentionWrapper
