import React from 'react'
import Container from 'react-bootstrap/Container'
import { Page } from 'v2'
import {TopNavbar} from 'navs'
import { PageCard } from 'pages'
import styled from 'styled-components'
import { ExternalLink } from 'links'

const Wrapper = styled.div`
  h2 {
    text-align: center;
    padding-bottom: ${props => props.theme.space.l};
  }

  h3 {
    padding-bottom: ${props => props.theme.space.m};
  }

  p {
    padding-bottom: ${props => props.theme.space.m};
  }

  li {
    padding-bottom: ${props => props.theme.space.s};
  }
`

function SupportPage(props) {

  let renderResources = props.resources.map(function(resource) {
    return(
      <li key={resource.url}>
        {resource.type}:
        <ExternalLink
          href={resource.url}
          text={` ${resource.text}`}
        />
      </li>
    )
  })

  return (
    <Container fluid>
      <TopNavbar />
      <Page>
        <PageCard>
          {props.navigation}
          <Wrapper>
            <h2>{props.heading}</h2>

            <h3>
              The following support resources are at your disposal:
            </h3>

            <ul>
              {renderResources}

              <li>
                Webinar:
                <ExternalLink
                  href='https://app.stacked.group/communities/1c21e73f-4419-46e5-bc58-7243cf7f2607/community-events'
                  text=' Register for one of our upcoming support webinars'
                />
              </li>

              <li>
                Discovery call:
                <ExternalLink
                  href='https://calendly.com/charlie-tarr-stacked/stacked-discovery-call'
                  text=' Book a 1-2-1 discovery call with a member of our customer success team'
                />
              </li>
            </ul>

            <h3>
              Still need help?
            </h3>

            <p>
              Hit the live chat button in the bottom right corner of the screen and a member of our customer success team will be happy to help you.
            </p>
          </Wrapper>
        </PageCard>
      </Page>
    </Container>
  )
}

export default SupportPage
