import React from 'react'
import { PencilFill } from 'react-bootstrap-icons'
import Button from './Button'

function EditResourceButton(props) {
  return (
    <Button
      onClick={props.onClick}
      content={<span><PencilFill /> {props.text}</span>}
      backgroundColor={props => props.theme.colors.offWhite}
      color={props => props.theme.colors.black}
      size={props => props.theme.space.s}
    />
  )
}

export default EditResourceButton
