import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  padding-bottom: ${props => props.theme.space.m};
`

function PageHeading(props) {
  return (
    <Wrapper id='page-heading'>
      <h2>{props.text}</h2>
    </Wrapper>
  )
}

export default PageHeading