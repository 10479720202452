import React from 'react'
import styled from 'styled-components'
import { TextAreaWrapper } from 'forms'
import { LabelWrapper } from 'forms'

const FieldWrapper = styled.div`
  padding-bottom: ${props => props.theme.space.l}
`

function TextAreaInput(props) {
  return (
    <FieldWrapper>
      <LabelWrapper
        label={props.label}
        name={props.name}
        toolTipContent={props.toolTipContent}
        required={props.required}
      />
      <TextAreaWrapper
        name={props.name}
        type={props.type}
        rows={props.rows}
        placeholder={props.placeholder}
      />
    </FieldWrapper>
  )
}

export default TextAreaInput
