import React from 'react'
import styled from 'styled-components'
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined'
import Badge from '@material-ui/core/Badge'
import { useCurrentAccountUser } from 'functions'
import { Link } from 'react-router-dom'

const IconWrapper = styled.div`
  height: 100%;
  justify-content: flex-end;

  display: flex;
  align-items: center;

  color: ${props => props.theme.colors.white};
  padding-right: 25px;
  cursor: pointer;

  .MuiBadge-badge {
    border-radius: 0.25rem;
    background-color: ${props => props.theme.colors.red};
    padding: 0;
    border: 2px solid ${props => props.theme.colors.white};
    font-weight: 600;
    min-width: 17px;
    z-index: unset;
  }
`

function NotificationBell() {
  const currentAccountUser = useCurrentAccountUser()

  return (
    <div>
      <Link to={`/account-users/${currentAccountUser.id}/activity-feed-items`}>
        <IconWrapper aria-haspopup="true">
          <Badge
            badgeContent={currentAccountUser.counters.unreadActivityFeedItems}
            max={99}
            overlap="circle"
          >
            <NotificationsNoneOutlinedIcon />
          </Badge>
        </IconWrapper>
      </Link>
    </div>
  )
}

export default NotificationBell
