import React from 'react'
import { ViewPage } from 'pages'
import Navigation from 'v2/spas/dashboard/components/view/navigation.tsx'
import { Actions } from 'pages'
import Grid from 'v2/spas/dashboard/components/view/grid.tsx'

function View() {
  return (
    <ViewPage>
      <Navigation />

      <Actions heading='Welcome to Stacked' />

      <Grid />
    </ViewPage>
  )
}

export default View
