import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDevice } from 'functions'
import { LogoGreen } from 'assets'
import { Logo } from 'assets'
import { useCurrentAccountUser } from 'functions'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  
  a {
    display: flex;
  }
`

const LogoWrapper = styled.div`
  display: flex;  
  align-items: center;
  
  img {
    height: ${props => props.theme.space.l};
  }

  @media ${props => props.theme.devices.mobile} {
    img {
      height: 24px;
    }
  }
`

const Name = styled.div`
  padding-left: ${props => props.theme.space.m};
  font-weight: 800;
  font-size: ${props => props.theme.space.l};
  color: ${props => props.brandColour ? props.brandColour : props.theme.colors.white};

  @media ${props => props.theme.devices.mobile} {
    font-size: 24px;
  }
`

function Brand(props) {
  let logo

  const currentAccountUser = useCurrentAccountUser()

  if (props.navbarTheme === 'white') {
    logo = LogoGreen
  } else {
    logo = Logo
  }

  let brandColour

  if (props.navbarTheme === 'white') {
    brandColour = props => props.theme.colors.green
  } else {
    brandColour = props => props.theme.colors.white
  }

  const deviceNameForTesting = useDevice()

  if (currentAccountUser.id) {
    return (
      <Wrapper id="brand">
        <Link to="/">
          <LogoWrapper id="logo">
            <img src={logo} alt="Stacked Logo" />
          </LogoWrapper>
          <Name id="name" brandColour={brandColour}>
            Stacked
            {/* {deviceNameForTesting}  */}
          </Name>
        </Link>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper id="brand">
        <a href='https://www.stacked.group/'>
          <LogoWrapper id="logo">
            <img src={logo} alt="Stacked Logo" />
          </LogoWrapper>
          <Name id="name" brandColour={brandColour}>
            Stacked
          </Name>
        </a>
      </Wrapper>
    )
  }
}

export default Brand
