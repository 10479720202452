import React from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'

const Wrapper = styled.div`
  ${'' /* display: flex; */}
  ${'' /* height: 100%; */}
`

function DealFinancialsModalBody(props) {
  let contents

  if (props.visual) {
    contents = (
      <Modal.Body style={{height: '75vh', 'overflow-y': 'auto'}}>
        {props.children}
      </Modal.Body>
    )
  } else {
    contents = (
      <Modal.Body
        style={{'max-height': 'calc(100vh - 100px)', 'overflow-y': 'auto'}}
      >
        {props.children}
      </Modal.Body>
    )
  }

  return <Wrapper>{contents}</Wrapper>
}

export default DealFinancialsModalBody
