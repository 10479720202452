import React from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { Button } from 'buttons'

const ModalBodyWrapper = styled.div`
  text-align: center;
`

const ModalHeading = styled.div`
    padding-top: ${props => props.theme.space.l};
  padding-left: ${props => props.theme.space.m};
  padding-right: ${props => props.theme.space.m};
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;

  color: ${props => props.theme.colors.black};
`

const ModalDescription = styled.div`
  padding: ${props => props.theme.space.m};
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  p {
    margin: 2.5px;
  }

  color: ${props => props.theme.colors.black};
`

const ModalFooterWrapper = styled.div`
  .modal-footer {
    display: flex;
    justify-content: center;
  }
`

function DeleteConfirmationModal(props) {
  const heading = props.heading ? props.heading : 'Delete the item?'
  const description = props.description
    ? props.description
    : 'Are you sure you want to delete the item? This cannot be undone.'

  return (
    <Modal centered show={props.show} size="md">
      <ModalBodyWrapper>
        <Modal.Body>
          <ModalHeading>{heading}</ModalHeading>
          <ModalDescription>{description}</ModalDescription>
        </Modal.Body>
        <ModalFooterWrapper>
          <Modal.Footer>
            <Button
              content="Cancel"
              backgroundColor={props => props.theme.colors.offWhite}
              color={props => props.theme.colors.black}
              onClick={() => props.setShow(false)}
            />
            <Button
              id="confirm-delete-button"
              content="Delete"
              backgroundColor={props => props.theme.colors.red}
              color={props => props.theme.colors.white}
              onClick={props.handleDelete}
            />
          </Modal.Footer>
        </ModalFooterWrapper>
      </ModalBodyWrapper>
    </Modal>
  )
}

export default DeleteConfirmationModal
