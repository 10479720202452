import React from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { logout } from 'redux/actions/authentication/logout'
import { connect } from 'react-redux'
import { useCurrentAccountUser } from 'functions'
import { useInitials } from 'functions'
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import Image from 'react-bootstrap/Image'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    zIndex: 1,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}))

const Wrapper = styled.div`
  align-self: center;

  a {
    font-family: 'Nunito Sans';
    color: black;
  }
`

const Circle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${props => props.theme.colors.white};
  cursor: pointer;
`

const Initials = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
`

const ImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;

  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
`

function ProfileMenu(props) {
  // const { enqueueSnackbar } = useSnackbar()
  // const classes = useStyles()
  // const [open, setOpen] = React.useState(false)
  // const anchorRef = React.useRef(null)

  // const currentAccountUser = useCurrentAccountUser()
  // const initials = useInitials(currentAccountUser)

  // const handleToggle = () => {
  //   setOpen(prevOpen => !prevOpen)
  // }

  // const handleClose = event => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return
  //   }

  //   setOpen(false)
  // }

  // function handleListKeyDown(event) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault()
  //     setOpen(false)
  //   }
  // }

  // function logOut(event) {
  //   handleClose(event)
  //   props.logout(handleLogOut)
  // }

  // function handleLogOut(response) {
  //   if (response.status === 'success') {
  //     enqueueSnackbar('Logged out successfully!')
  //     window.location.href = '/'
  //   } else if (response.status === 'error') {
  //     enqueueSnackbar(response.error, { variant: 'error' })
  //     window.setTimeout(function () {
  //       window.location.reload()
  //     }, 1000)
  //   }
  // }

  // const prevOpen = React.useRef(open)

  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus()
  //   }

  //   prevOpen.current = open
  // }, [open])

  // function userAvatar() {
  //   if (currentAccountUser.avatarUrl) {
  //     return (
  //       <ImageWrapper>
  //         <Image roundedCircle src={currentAccountUser.avatarUrl} />
  //       </ImageWrapper>
  //     )
  //   } else {
  //     return <Initials>{initials}</Initials>
  //   }
  // }

  return null

  // return (
  //   <div className={classes.root}>
  //     <Wrapper>
  //       <Circle
  //         ref={anchorRef}
  //         aria-controls={open ? 'menu-list-grow' : undefined}
  //         aria-haspopup="true"
  //         onClick={handleToggle}
  //       >
  //         {userAvatar()}
  //       </Circle>
  //       <Popper
  //         open={open}
  //         anchorEl={anchorRef.current}
  //         role={undefined}
  //         transition
  //         disablePortal
  //       >
  //         {({ TransitionProps, placement }) => (
  //           <Grow
  //             {...TransitionProps}
  //             style={{
  //               transformOrigin:
  //                 placement === 'bottom' ? 'center top' : 'center bottom',
  //             }}
  //           >
  //             <Paper>
  //               <ClickAwayListener onClickAway={handleClose}>
  //                 <MenuList
  //                   autoFocusItem={open}
  //                   id="menu-list-grow"
  //                   onKeyDown={handleListKeyDown}
  //                   style={{ fontFamily: 'Nunito Sans', color: 'black' }}
  //                 >
  //                   {/* <Link to="/mortgage-broker-user-details">
  //                     <MenuItem>My Profile</MenuItem>
  //                   </Link> */}
  //                   <MenuItem onClick={logOut}>Logout</MenuItem>
  //                 </MenuList>
  //               </ClickAwayListener>
  //             </Paper>
  //           </Grow>
  //         )}
  //       </Popper>
  //     </Wrapper>
  //   </div>
  // )
}

const mapDispatchToProps = {
  logout: logout,
}

export default connect(null, mapDispatchToProps)(ProfileMenu)
