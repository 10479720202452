import React from 'react'
import styled from 'styled-components'
import { NavbarLink } from 'navs'
import ProfileMenu from './profile-menu.js'
import { useCurrentAccount } from 'functions'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

function Links(props) {

  let currentAccount = useCurrentAccount()

  return (
    <Wrapper>
      <NavbarLink
        to={`/accounts/${currentAccount.id}/mortgage-applications`}
        text="Mortgage applications"
        textColour={props => props.theme.colors.white}
        activeBarColour={props => props.theme.colors.white}
      />
      {/* <Notification /> */}
      <ProfileMenu />
    </Wrapper>
  )
}

export default Links
