import React from 'react'
import { ArrowClockwise } from 'react-bootstrap-icons';
import Button from './Button';
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  padding-top: ${props => props.theme.space.m};
  padding-bottom: ${props => props.theme.space.m};

  p {
    cursor: pointer;
  }
`

function LoadMoreButton(props) {
  const color = props => props.theme.colors.primary
  const backgroundColor = props => props.theme.colors.offWhite

  let text = props.text
  let loadMore = props.loadMore

  return (
    <Wrapper>
      <Button
        onClick={loadMore}
        content={<span><ArrowClockwise /> {text}</span>}
        size={props => props.theme.space.s}
        color={color}
        backgroundColor={backgroundColor}
      />
    </Wrapper>
  )
}

export default LoadMoreButton
