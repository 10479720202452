import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  padding-top: 50px;
  padding-bottom: 50px;

  .MuiCircularProgress-colorPrimary {
    color: ${props =>
    props.color ? props.color : props.theme.colors.primary} !important;
  }
`

function Spinner(props) {
  return (
    <Wrapper color={props.color}>
      <CircularProgress />
    </Wrapper>
  )
}

export default Spinner
