import React from 'react'
import styled from 'styled-components'
import Button from './Button.js'

const Wrapper = styled.div`
  #button {
    border-radius: 8px;
    width: 100%;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.isSubmitting ? props.theme.colors.lightGrey : props.theme.colors.primary};
  }
`

function SubmitButton(props) {
  function content() {
    if (props.isSubmitting) {
      return 'Submitting...'
    } else {
      return props.content
    }
  }

  return (
    <Wrapper id="submit-button" isSubmitting={props.isSubmitting}>
      <Button
        onClick={props.onClick}
        submit
        disabled={props.isSubmitting}
        content={content()}
      />
    </Wrapper>
  )
}

export default SubmitButton
