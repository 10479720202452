import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.grey};
`

function HideModalIcon(props) {
  return (
    <Wrapper
      id="hide-modal-icon"
      onClick={() => props.setShow(false)}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
    >
      X
    </Wrapper>
  )
}

export default HideModalIcon

HideModalIcon.propTypes = {
  setShow: PropTypes.func.isRequired,
}
