import React from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Spinner} from 'functional-components'

const ModalBodyWrapper = styled.div`
  min-height: 250px;
  display: flex;
  align-items: center;

  .modal-body {
    .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate {
      height: 80px !important;
      width: 80px !important;
      color: ${props => props.theme.colors.primary};
    }
  }
`

const ModalHeading = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;

  color: ${props => props.theme.colors.black};
`

const ModalDescription = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  text-align: center;
  padding-top: 15px;
  padding-bottom: 50px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;

  color: ${props => props.theme.colors.grey};
`

function SpinnerModal(props) {
  let heading

  if (props.heading) {
    heading = <ModalHeading>{props.heading}</ModalHeading>
  }

  let description

  if (props.description) {
    description = <ModalDescription>{props.description}</ModalDescription>
  }

  function onHide() {
    if (props.setShow) {
      props.setShow(false)
    }
  }

  return (
    <Modal centered show={props.show} onHide={onHide}>
      <ModalBodyWrapper>
        <Modal.Body>
          <Row>
            <Col sm="12">
              <Spinner />
            </Col>

            <Col sm="12">{heading}</Col>

            <Col sm="12">{description}</Col>
          </Row>
        </Modal.Body>
      </ModalBodyWrapper>
    </Modal>
  )
}

export default SpinnerModal
