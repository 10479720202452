import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { QuestionCircle } from 'react-bootstrap-icons';
import styled from 'styled-components'


const Wrapper = styled.div`
  svg {
    cursor: pointer;
    color: ${props => props.theme.colors.grey};
  }
`

function TriggerExample(props) {
  return (
    <Wrapper id="tooltip">
      <OverlayTrigger
        overlay={
          <Tooltip id="button-tooltip" {...props}>
            {props.content}
          </Tooltip>
        }
      >
        <QuestionCircle />
      </OverlayTrigger>
    </Wrapper>
  );
}

export default TriggerExample;
