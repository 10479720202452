function processQuerySuccess({
  dispatch: dispatch,
  handler: handler,
  object: object,
}) {
  dispatch(handler({
    object: object,
  }))
}

export default processQuerySuccess