import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Telegram } from 'react-bootstrap-icons'
import Button from './Button'

const ButtonWrapper = styled.div`
  padding-right: ${props => props.paddingRight || '15px'};

  #button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;

    #button-text {
      padding-left: 5px;
    }
  }

  svg {
    color: ${props => props.color || props.theme.colors.black};
    height: 20px;
    width: 20px;
  }
`

function InviteResourceButton({
  backgroundColor = props => props.theme.colors.green, 
  color = props => props.theme.colors.white, 
  text, 
  fontSize, 
  paddingRight, 
  onClick
}) {
  return (
    <ButtonWrapper color={color} paddingRight={paddingRight}>
      <Button
        onClick={onClick}
        icon={<Telegram />}
        text={text}
        height="34px"
        backgroundColor={backgroundColor}
        fontSize={fontSize}
        fontWeight="bold"
        color={color}
      />
    </ButtonWrapper>
  )
}

export default InviteResourceButton
