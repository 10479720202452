import React from 'react'
import Button from './Button'
import { ArrowClockwise } from 'react-bootstrap-icons'
import {useDevice} from 'functions'

function VisualiseTextButton(props) {
  const device = useDevice()

  let buttonText = device === 'mobile' || device === 'tablet' ? '' : 'Recompile'

  return (
    <Button
      onClick={() => props.recompile()}
      content={<span><ArrowClockwise /> {buttonText}</span>}
      size={props => props.theme.space.s}
    />
  )
}

export default VisualiseTextButton
