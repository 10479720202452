import React from 'react'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'

const Line = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #ececec;

  margin-top: ${props => props.theme.space.m};
  margin-bottom: ${props => props.theme.space.m};
`

function Divider(props) {
  return (
    <Row>
      <Line id="divider" />
    </Row>
  )
}

export default Divider
