import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  padding-right: ${props => props.theme.space.l};
  display: flex;

  @media ${props => props.theme.devices.mobile} {
    padding-right: ${props => props.theme.space.m};
  }
`

const Name = styled.div`
  font-weight: ${props => (props.active ? 800 : 'normal')};
  font-size: ${props => props.theme.space.m};
  color: ${props => props.textColour ? props.textColour : props.theme.colors.black};
  padding-top: ${props => props.theme.space.s};
  padding-bottom: ${props => props.theme.space.s};

  @media ${props => props.theme.devices.laptop} {
    font-size: 14px;
  }

  @media ${props => props.theme.devices.tablet} {
    font-size: 14px;
  }

  @media ${props => props.theme.devices.mobile} {
    font-size: 13px;
  }
`

const ActiveBar = styled.div`
  height: 3px;
  display: ${props => (props.active ? '' : 'none')};
  background-color: ${props => props.activeBarColour ? props.activeBarColour : props.theme.colors.black};
`

function NavbarLink(props) {
  let active = window.location.pathname === props.to

  function renderLink() {
    if (props.external) {
      return (
        <Wrapper id="navbar-link">
          <a href={props.to} target='_blank' rel="noopener noreferrer">
            <Name textColour={props.textColour} active={active}>
              {props.text}
            </Name>
          </a>
        </Wrapper>
      )
    } else {
      return (
        <Wrapper id="navbar-link">
          <Link to={props.to}>
            <Name textColour={props.textColour} active={active}>
              {props.text}
            </Name>
          </Link>
          <ActiveBar
            active={active}
            activeBarColour={props.activeBarColour}
          ></ActiveBar>
        </Wrapper>
      )
    }
  }

  return (
    renderLink()
  )
}

export default NavbarLink
