import React from 'react'
import styled from 'styled-components'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import {MobileSidebarLinks} from 'links'
import PropTypes from 'prop-types'
import {MobileSidebarLink} from 'links'
import { Row } from 'react-bootstrap'
import { useCurrentUser } from 'functions'
import { logout } from 'redux/actions/authentication/logout'
import { connect } from 'react-redux'
import { CalendarIcon } from 'icons';
import { useCurrentAccount } from 'functions'
import { useCurrentAccountUser } from 'functions'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    color: ${props =>
    props.navbarTheme ? props.theme.colors.green : props.theme.colors.white};
  }
`

function MobileSidebar(props) {
  const [state, setState] = React.useState({
    left: false,
  })

  const currentAccount = useCurrentAccount()
  const currentUser = useCurrentUser()
  const currentAccountUser = useCurrentAccountUser()

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const anchor = 'left'

  function logOut(event) {
    props.logout(handleLogOut)
  }

  function handleLogOut() {
    window.location.href = '/'
  }

  function links() {
    if (currentUser.id) {
      return (
        <Row>
          <MobileSidebarLink to="/prospective-properties" text="Prospective properties" />
          <MobileSidebarLink to="/owned-properties" text="Portfolio" />
          <MobileSidebarLink to={`/accounts/${currentAccount.id}/account-users/${currentAccountUser.id}`} text="My stuff" />
          <MobileSidebarLink to={`/accounts/${currentAccount.id}`} text="Account" />
          <MobileSidebarLink to="/profile" text="My profile" />
          <MobileSidebarLink onClick={logOut} text="Logout" />
        </Row>
      )
    } else if (window.location.pathname === '/mastermind-sign-up') {
      return <Row></Row>
    } else {
      return (
        <Row>
          <MobileSidebarLink to="/login" text="Sign in" />
          <MobileSidebarLink to="/sign-up" text="Try it FREE" />
        </Row>
      )
    }
  }

  const renderCalendar = () => {
    if (currentUser.id) {
      return <CalendarIcon />
    }
  }

  return (
    <Wrapper navbarTheme={props.navbarTheme}>
      {renderCalendar()}
      <MenuIcon onClick={toggleDrawer(anchor, true)} />
      <Drawer
        id="drawer"
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        <MobileSidebarLinks anchor={anchor} toggleDrawer={toggleDrawer}>
          {links()}
        </MobileSidebarLinks>
      </Drawer>
    </Wrapper>
  )
}

const mapDispatchToProps = {
  logout: logout,
}

export default connect(null, mapDispatchToProps)(MobileSidebar)

MobileSidebar.propTypes = {
  navbarTheme: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}
